import types from './types.cjs';
import { DEV } from '../../lib/helpers';

export const initialState = {
	loading: false,
	error: null,
	cookieConsent: null,
	colorMode: DEV ? 'dark' : 'light',
	lightMode: !DEV,
	darkMode: DEV
};

export default function appReducer(state = initialState, action = {}) {
	switch (action.type) {

		case types.APP_ERROR: {
			return {
				...state,
				loading: false,
				error: action.error
			};
		}

		case types.APP_BUSY: {
			return {
				...state,
				loading: true
			};
		}

		case types.APP_READY: {
			return {
				...state,
				loading: false
			};
		}

		case types.SET_COLOR_MODE: {
			return {
				...state,
				colorMode: action.data,
				lightMode: action.data === 'light',
				darkMode: action.data === 'dark'
			};
		}

		case types.SET_COOKIE_CONSENT: {
			return {
				...state,
				cookieConsent: action.data
			};
		}
	}

	return state;
}

appReducer.initialState = initialState;
