import * as React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import ShopIcon from '@mui/icons-material/ShoppingCart';
import { useDeferredUser } from 'context/user';
import { appData } from 'config/data.cjs';

function ShopButton(props) {
	const [ {userType} ] = useDeferredUser();

	const {
		children,
		userType: userTypeProp = userType,
		href,
		...rest
	} = props;

	const isRetail = userTypeProp === 'retail';
	const shopTitle = isRetail ? 'Online-Shop' : 'Profi-Shop';

	return (
		<Button
			color="primary"
			variant="outlined"
			href={href || appData.contact[isRetail ? 'shop' : 'profiShop']}
			startIcon={<ShopIcon/>}
			{...rest}
		>
			{children || shopTitle}
		</Button>
	);
}

ShopButton.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	href: PropTypes.string,
	userType: PropTypes.oneOf(['retail', 'wholesale'])
};

export default React.memo(ShopButton);
