import types from './types.cjs';

export function appError(error) {
	return {
		type: types.APP_ERROR,
		error
	};
}

export function appBusy() {
	return {
		type: types.APP_BUSY
	};
}

export function appReady() {
	return {
		type: types.APP_READY
	};
}

export function setColorMode(data, writeCookie = true) {
	if (writeCookie) {
		document.cookie = `colorMode=${data};expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/`;
	}

	return {
		type: types.SET_COLOR_MODE,
		data
	};
}

export function setCookieConsent(open = true) {
	return {
		type: types.SET_COOKIE_CONSENT,
		data: Boolean(open)
	};
}
