import * as React from 'react';
import PropTypes from 'prop-types';
import FullscreenImgDialog from '../FullscreenImgDialog';
import Dialog from '../Dialog';
import Error from '../Error';

const Context = React.createContext();
const { Provider } = Context;

export function useDialog() {
	return React.useContext(Context);
}

function DialogProvider(props) {
	const {
		children,
		pages = {},
		FullscreenDialogComponent = FullscreenImgDialog,
		DialogComponent = Dialog,
		TransitionProps,
		dataPath = 'data',
		...rest
	} = props;

	const [ dialogs, setDialogs ] = React.useState([]);

	function openDialog(event, {target, ...options} = {}) {
		if (!event) {
			console.warn('Event prop missing!');
			return;
		}

		target = target || event.currentTarget;

		if (!target) {
			console.warn('Target missing!');
			return;
		}

		try {
			const id = target.id || target.getAttribute('data-id') || Math.random().toString(16);
			const page = pages?.[id];
			const title = target.getAttribute('data-title') || target.title || (page && (page.headline || page.title)) || '';
			const src = target.getAttribute('data-src') || (page && page.externalLink) || '';

			const dialog = {
				id,
				title,
				src: src.startsWith(`/${dataPath}`) || src.startsWith('/images') || !src.startsWith('/') ? src : '',
				fullScreen: true,
				...options,
				open: true
			};

			setDialogs([...dialogs, dialog]);

			event.preventDefault();

			return dialogs.length;
		} catch (err) {
			console.error(err);
		}
	}

	function closeDialog(key) {
		const dialog = dialogs[key];
		if (dialog) {
			dialog.open = false;
			setDialogs([...dialogs]);
		}
	}

	const value = {
		openDialog,
		closeDialog
	};

	return (
		<Provider value={value}>
			{children}
			{dialogs.map((dialog, key) => {
				const {
					content,
					open,
					id,
					src,
					title,
					fullScreen,
					...dialogProps
				} = dialog;

				const DialogComponent = fullScreen ? FullscreenDialogComponent : DialogComponent;

				const handleClose = () => {
					closeDialog(key);
					if (dialogProps && dialogProps.onClose) {
						dialogProps.onClose();
					}
				};

				const handleExited = () => {
					dialogs.splice(key, 1);
					setDialogs([...dialogs]);
					if (dialogProps && dialogProps.onExited) {
						dialogProps.onExited();
					}
				};

				return (
					<DialogComponent
						{...rest}
						{...dialogProps}
						src={fullScreen ? src : undefined}
						id={fullScreen ? id : undefined}
						key={key}
						onClose={handleClose}
						TransitionProps={{
							onExited: handleExited,
							...TransitionProps
						}}
						open={open}
						title={title}
					>
						{src ? (
							content
						) : (
							<Error title="Fehler 404: Die Seite konnte nicht gefunden werden!"/>
						)}
					</DialogComponent>
				);
			})}
		</Provider>
	);
}

DialogProvider.propTypes = {
	DialogComponent: PropTypes.elementType,
	FullscreenDialogComponent: PropTypes.elementType,
	pages: PropTypes.object,
	dataPath: PropTypes.string
};

export default React.memo(DialogProvider);
