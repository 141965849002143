const pathToRegExp = require('path-to-regexp');

class PageDataHelpers {
	static getPage(pages, pathname) {
		const { matchPage, findPage } = PageDataHelpers;
		return findPage(pages, pathname) || matchPage(pages, pathname) || pages.notFound || {};
	}

	static get pathToRegExp() {
		return pathToRegExp;
	}

	static findPageParent(pages = {}, subPath = '', pathname = '') {
		const { findPageParent, findPage } = PageDataHelpers;

		if (subPath) {
			pathname = pathname || subPath;
			subPath = subPath.substring(0, subPath.lastIndexOf('/'));
			const page = findPage(pages, subPath);
			if (page) {
				return page;
			}
			if (subPath) {
				return findPageParent(pages, subPath, pathname);
			}
		}
		return null;
	}

	static findPage(pages = {}, pathname = '') {
		if (pathname) {
			for ( let [id, page] of Object.entries(pages)) {
				if (page?.pathname && (page.pathname === pathname || page.aliases?.includes?.(pathname))) {
					if (!page.id) {
						page.id = id;
					}
					return page;
				}
			}
		}
		return null;
	}

	static matchPage(pages = {}, pathname = '') {
		const { findPageParent } = PageDataHelpers;

		if (pathname) {
			for ( let [id, page] of Object.entries(pages)) {
				if (typeof page?.match === 'string') {
					const result = pathToRegExp(page.match).exec(pathname);
					if (result) {
						if (page.pathname === result[0]) {
							if (!page.id) {
								page.id = id;
							}
							return page;
						}
						return PageDataHelpers.findPageParent(pages, pathname);
					}
				}
			}
		}
		return null;
	}

	static matchPathParams(match = '', pathname = '') {
		const keys = [];
		const result = pathToRegExp(match, keys).exec(pathname);
		if (result) {
			const params = {};
			result.slice(1).forEach((val, key) => {
				params[[keys[key].name]] = val;
			});
			return params;
		}
		return null;
	}

	static parsePages(pages = {}) {
		Object.entries(pages).map(([id, {pathname, asPath, slugs, dataId}]) => {
			pages[id].id = id;

			if (asPath && !pathname) {
				pathname = pages[id].pathname = `/${id}`;
			}

			if (slugs) {
				slugs.forEach((slug) => {
					if (pages[slug]) {
						pages[slug].parentId ??= id;
						if (dataId) {
							pages[slug].dataId ??= dataId;
						}
						if (pathname && pages?.[slug]?.asPath && !pages?.[slug]?.pathname) {
							pages[slug].pathname = `${pathname}/${slug}`;
						}
					}
				});
			}
		});
		return pages;
	}

	static parseAppData(appData = {}) {
		appData.pages = PageDataHelpers.parsePages(appData.pages);
		const find = (menu => {
			menu.forEach(item => {
				if (!appData.pages[item.id]) {
					throw new Error(`Page "${item.id}" missing!`);
				}
				item.parents = item.parents || [];
				if (item.children) {
					item.children = item.children.map(child => {
						return {
							...child,
							parents: item.parents ? item.parents.concat(item.id) : [item.id]
						};
					});
					find(item.children);
				}
				const page = appData.pages[item.id];
				page.menu = item;
				page.id = item.id;
			});
		});

		find(appData.menu);
		return appData;
	}

	static getSlugs(pages = {}) {
		const ignoreSlugs = [];

		const slugs = Object.entries(pages).map(([id, page = {}]) => {
			if (Array.isArray(page.slugs)) {
				ignoreSlugs.push(...page.slugs);
				return id;
			}
			if ((page.asPath || page.pathname) && !ignoreSlugs.includes(id)) {
				return id;
			}
		}).filter(Boolean).slice(1);

		if (slugs.length > 0) {
			const { asPath, pathname } = Object.values(pages)[0] || {};
			if (asPath || pathname) {
				Object.values(pages)[0].slugs = slugs;
			}
		}

		return pages;
	}

	static getRewrites(pages = {}) {
		const rewrites = [];
		Object.entries(pages).map(([id, page = {}]) => {
			const { asPath, pathname, match } = page;

			if (Array.isArray(page.rewrites)) {
				page.rewrites.forEach(rewrite => {
					const { source, destination } = rewrite || {};
					if (source && destination) {
						rewrites.push({
							source,
							destination
						});
					}
				});
			} else if (page.rewrites !== false && asPath && pathname && asPath.startsWith('/') && pathname.startsWith('/')) {
				rewrites.push({
					source: `${asPath}${match ? '/:slug*' : ''}`,
					destination: `${pathname}${match ? '/:slug*' : ''}`
				});
			}
		});
		return rewrites;
	}

	static getRedirects(pages = {}) {
		const redirects = [];
		Object.entries(pages).map(([id, page = {}]) => {
			if (Array.isArray(page.redirects)) {
				page.redirects.forEach(rewrite => {
					const { source, destination, permanent = true } = rewrite || {};
					if (source && destination) {
						redirects.push({
							source,
							destination,
							permanent
						});
					}
				});
			}
		});
		return redirects;
	}
}

module.exports = PageDataHelpers;