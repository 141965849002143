import * as React from 'react';
import PropTypes from 'prop-types';
import Iframe from '../Iframe';
import Img from '../Img';
import FullscreenDialog from '../FullscreenDialog';
import { isImage, isLink } from '../../lib/helpers';

function FullscreenImgDialog(props) {
	const {
		children,
		title,
		src,
		open,
		centered,
		onClose,
		imgProps,
		ImgProps,
		IframeProps,
		...rest
	} = props;

	const isImageSrc = isImage(src);
	const isLinkSrc = isLink(src);

	return (
		<FullscreenDialog
			onClose={onClose}
			open={open}
			title={title}
			centered={isImageSrc || centered}
			{...rest}
		>
			{isImageSrc ? (
				<Img
					responsive={false}
					lazyload={false}
					src={src}
					title={title}
					imgProps={imgProps}
					{...ImgProps}
				/>
			) : (
				isLinkSrc ? (
					<Iframe src={src} {...IframeProps} />
				) : (
					children
				)
			)}
		</FullscreenDialog>
	);
}

FullscreenImgDialog.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	src: PropTypes.string,
	open: PropTypes.bool,
	centered: PropTypes.bool,
	onClose: PropTypes.func,
	imgProps: PropTypes.object,
	ImgProps: PropTypes.object,
	IframeProps: PropTypes.object
};

export default React.memo(FullscreenImgDialog);
