import { useApp } from 'react-app/context/app';
import { useGa } from 'lib/ga';

export function useCookieConsent() {
	const [, {setCookieConsent}] = useApp();
	const { resetGa } = useGa();

	function handleCookieConsentClose() {
		setCookieConsent(true);
	}

	function handleCookieConsentReset(event) {
		event.preventDefault();
		resetGa();
		setCookieConsent(false);
	}

	return {
		handleCookieConsentClose,
		handleCookieConsentReset
	};
}
