import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from 'styles';
import AppBar from 'react-app/components/FullscreenDialogAppBar';
import { useBrowser } from 'react-app/context/browser';
import Brand from 'components/Brand';

const useClasses = createClasses((theme) => ({
	root: {
		justifyContent: 'center',
		borderBottom: `solid 1px ${theme.vars.palette.divider}`,
		boxShadow: 'none'
	},
	brand: {
		marginRight: theme.spacing(2)
	}
}), {
	name: 'FullscreenDialogAppBar'
});

function FullscreenDialogAppBar(props) {
	const {
		classes: classesProp,
		className,
		BrandProps,
		onClick,
		...rest
	} = props;

	const classes = useClasses(props);

	const browser = useBrowser();
	const { isWidthDown } = browser;
	const smDown = isWidthDown('sm', browser.breakpoint);

	const brand = (
		<Brand
			onClick={onClick}
			variant="button"
			{...BrandProps}
			className={clsx(
				classes.brand,
				BrandProps?.className
			)}
		/>
	);

	return (
		<AppBar
			brand={smDown && props.title ? null : brand}
			color="default"
			{...rest}
			className={classes.root}
		/>
	);
}

FullscreenDialogAppBar.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	BrandProps: PropTypes.object,
	onClick: PropTypes.func
};

export default React.memo(FullscreenDialogAppBar);
