import * as React from 'react';
import PropTypes from 'prop-types';
import NewsCard from 'components/News/NewsCard';
import { useEventBooking } from './EventBooking';
import { news as sizes } from 'config/sizes.cjs';

function EventCard(props) {
	const { fullWidth } = props;

	const {
		label,
		button: signUpButton,
		dialog: signUpDialog
	} = useEventBooking(props);

	const CardProps = {
		buttons: signUpButton
	};

	const NewsMediaProps = {
		children: label
	};

	const NewsDialogProps = {
		buttons: signUpButton,
		NewsMediaProps: {
			children: label
		}
	};

	return (
		<>
			<NewsCard
				showPublishedDate={Boolean(fullWidth)}
				showContent={Boolean(fullWidth)}
				noInfoButton={signUpButton}
				{...CardProps}
				{...props}
				NewsMediaProps={NewsMediaProps}
				NewsDialogProps={NewsDialogProps}
				maxWidth={sizes.width.sm}
			/>

			{signUpDialog}
		</>
	);
}

EventCard.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	imageSrc: PropTypes.string,
	soldOut: PropTypes.bool,
	showLabel: PropTypes.bool
};

export default React.memo(EventCard);
