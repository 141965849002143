import * as React from 'react';

const useLayoutEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

function useInit(fn, browserOnly = false) {
	const initRef = React.useRef(false);
	if (!initRef.current && (!browserOnly || typeof window !== 'undefined')) {
		if (typeof fn !== 'function') {
			throw new TypeError('Argument must be a function!');
		}
		initRef.current = true;
		return fn();
	}
}

function useInitValue(value) {
	const valueRef = React.useRef();
	if (!valueRef.current) {
		valueRef.current = value;
	}
	return valueRef.current;
}

function useDidMount(fn) {
	return React.useEffect(() => fn && fn(), []); /* eslint-disable-line */
}

function useWillUnmount(fn) {
	return React.useEffect(() => () => fn && fn(), []); /* eslint-disable-line */
}

function useDidUpdate(fn, conditions) {
	const didMountRef = React.useRef(false);
	React.useEffect(() => {
		if (!didMountRef.current) {
			didMountRef.current = true;
			return;
		}
		return fn && fn();
	}, [fn, conditions]);
}

function useMergeState(initial = {}) {
	const [state, setState] = React.useState(initial);
	return {
		state,
		setState: updater => setState(prev => (typeof updater === 'function'
			? { ...prev, ...updater(prev) }
			: { ...prev, ...updater }))
	};
}

function useEventListener(eventName, handler, {target = global, ...options} = {}) {
	const savedHandler = React.useRef();

	React.useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	React.useEffect(() => {
		const elem = (target && target.current) || target;
		const isSupported = elem && elem.addEventListener;
		if (!elem || !isSupported || !savedHandler?.current) return;

		const eventListener = event => savedHandler.current(event);
		elem.addEventListener(eventName, eventListener, options);
		return () => {
			elem.removeEventListener(eventName, eventListener);
		};
	}, [eventName, target, options]);
}

function usePrevious(value) {
	const ref = React.useRef();
	React.useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

function useNext(value) {
	const valueRef = React.useRef(value);
	const resolvesRef = React.useRef([]);

	React.useEffect(() => {
		if (valueRef.current !== value) {
			for (const resolve of resolvesRef.current) {
				resolve(value);
			}
			resolvesRef.current = [];
			valueRef.current = value;
		}
	}, [value]);

	return () => new Promise(resolve => {
		resolvesRef.current.push(resolve);
	});
}

function useDelay(delay = 0, initialOpen) {
	const isControlled = typeof initialOpen === 'boolean';
	initialOpen = Boolean(initialOpen);
	const [open, setOpen] = React.useState(initialOpen);
	const prevOpen = usePrevious(initialOpen);
	initialOpen = isControlled ? initialOpen : true;

	React.useEffect(() => {
		let timer;

		if (prevOpen !== initialOpen) {
			if (initialOpen) {
				timer = setTimeout(() => {
					setOpen(true);
				}, delay);
			} else {
				setOpen(false);
			}
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [delay, open, initialOpen, prevOpen]);

	return delay ? open : initialOpen;
}

function useMemoCallback(cb) {
	const data = React.useRef({
		callback: null,
		handler: null
	});
	data.current.callback = cb;
	if (!data.current.handler) {
		data.current.handler = (...args) => data.current.callback(...args);
	}
	return data.current.handler;
}

function useCallback(fn, deps) {
	const fnRef = React.useRef(fn);
	const depsRef = React.useRef(deps);

	useLayoutEffect(() => {
		fnRef.current = fn;
		depsRef.current = deps;
	});

	return React.useCallback((...args) => (0, fnRef.current)(...depsRef.current)(...args), []);
}

function useMounted() {
	const mountedRef = React.useRef(false);

	useDidMount(() => {
		mountedRef.current = true;
	});

	return mountedRef.current;
}

function useEffectOnce(effect) {
	const isExecuted = React.useRef(false);
	useDidMount(() => {
		if (isExecuted.current) {
			return;
		}
		effect();
		isExecuted.current = true;
	});
}

const useAsyncError = () => {
	const [_, setError] = React.useState();
	return React.useCallback(err => {
		setError(() => {
			throw err;
		});
	}, [setError]);
};

export {
	useInit,
	useInitValue,
	useDidMount,
	useWillUnmount,
	useDidUpdate,
	useMergeState,
	useEventListener,
	usePrevious,
	useNext,
	useDelay,
	useMemoCallback,
	useMounted,
	useCallback,
	useAsyncError,
	useEffectOnce
};

export { default as useWindowSize } from './useWindowSize';
export { default as useAsyncReducer } from './useAsyncReducer';
export { default as useResizeObserver } from './useResizeObserver';
export * from './useAsyncSetState';
