import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { Box } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useDelay } from '../../hooks';

const defaultProps = {
	grow: true,
	size: 'sm',
	sizes: {
		xs: 50,
		sm: 100,
		md: 150,
		lg: 200,
		xl: 300
	}
};

const useClasses = createClasses((theme, {size = defaultProps.size, sizes = defaultProps.sizes, legacy}) => ({
	root: {
		alignSelf: 'center'
	},
	legacy: {
		...theme.mixins.spinner,
		margin: 'auto',
		overflow: 'hidden',
		width: sizes[size],
		height: sizes[size],
		maxWidth: sizes[size],
		maxHeight: sizes[size],
		'&:before': {
			...theme.mixins.spinner['&:before'],
			top: 0,
			left: 0,
			backgroundSize: `${sizes[size]}px ${sizes[size]}px`,
			zIndex: 1
		}
	}
}), {
	name: 'RaLoading',
	mergeClassName: false
});

function Loading(props) {
	const {
		classes: classesProp,
		className,
		size = defaultProps.size,
		sizes = defaultProps.sizes,
		grow = defaultProps.grow,
		open: openProp,
		delay,
		legacy,
		...rest
	} = props;

	const initialOpen = typeof openProp === 'boolean' ? openProp : delay ? null : true;
	const open = useDelay(delay, initialOpen);

	const classes = useClasses(props);

	return (
		open ? (
			legacy ? (
				<Box
					{...rest}
					flexGrow={grow ? 1 : null}
					className={clsx(
						classes.root,
						classes.legacy,
						className
					)}
				/>
			) : (
				<CircularProgress
					disableShrink
					color="inherit"
					size={Number.isFinite(size) ? size : sizes[size] * 0.75}
					{...rest}
					className={clsx(
						classes.root,
						className
					)}
				/>
			)
		) : null
	);
}

Loading.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	size: PropTypes.oneOfType([
		PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs']),
		PropTypes.number
	]),
	sizes: PropTypes.object,
	grow: PropTypes.bool,
	open: PropTypes.bool,
	legacy: PropTypes.bool,
	delay: PropTypes.number
};

export default React.memo(Loading);
