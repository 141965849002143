const Lib = require('lib-js');
const MasterSharedHelpers = require('express-app/lib/shared-helpers.cjs');
const IS_WEBPACK = typeof WEBPACK_DEV !== 'undefined';

class SharedHelpers extends MasterSharedHelpers {
	static get IS_WEBPACK() {
		return IS_WEBPACK;
	}

	static get DEV() {
		return MasterSharedHelpers.IS_DEV;
	}

	static get BROWSER() {
		return MasterSharedHelpers.IS_BROWSER;
	}

	static getUserTypeData(userType = 'retail') {
		if (userType !== 'retail' && userType !== 'wholesale') {
			throw new Error ('Invalid user type!');
		}

		userType = userType || 'retail';

		return {
			userType,
			isRetail: userType === 'retail',
			isWholesale: userType === 'wholesale'
		};
	}

	static getPinterestLink(link = '', title = '') {
		return {
			title: `Mehr zum Thema ${title} auf unserem Pinterest-Profil`,
			href: '${contact.pinterest}/' + link,
			icon: 'pinterest',
			type: 'link',
			userType: 'retail'
		};
	}

	static getYoutubeId(str) {
		const matched = str && str.match(/(?<url>^https:\/\/(www\.)?youtu\.be\/|^https:\/\/(www\.)?youtube\.com\/embed\/)(?<id>[A-Za-z0-9_-]{11})/i);
		const { groups = {} } = matched || {};
		return groups.id || null;
	}

	static getYoutubeVideoLink(id = '', autoplay = true) {
		return `https://youtube.com/embed/${id}?rel=0${autoplay ? '&autoplay=1' : ''}`;
	}

	static getYoutubeVideoParams(id = '', config = {}) {
		const {
			res = 'maxresdefault',
			autoplay = true,
			video = true
		} = config;

		const src = SharedHelpers.getYoutubeVideoLink(id, autoplay);

		return {
			imageSrc: video ? `https://img.youtube.com/vi/${id}/${res}.jpg` : undefined,
			iframeSrc: src,
			to: !video ? src : undefined,
			video
		};
	}

	static isVideo(str) {
		return str && (/\.(mp4|ogg)$/i).test(str);
	}

	static convertDate(val) {
		if (!val) return null;

		const { dateFormat, formatDate } = SharedHelpers;

		try {
			return formatDate(new Date(val), dateFormat);
		} catch (err) {
			return val;
		}
	}

	static get dateFormat() {
		return {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit'
		};
	}

	static uniqueRandomArray(array = [], count = 1) {
		if (!Array.isArray(array)) {
			throw new TypeError('Expected an array');
		}
		if (!(Number.isInteger(count) && count >= 0)) {
			throw new TypeError('Expected a non-negative integer');
		}
		const result = [...new Set(Array.from({length: count}, () => array[Math.floor(Math.random() * array.length)]))];
		if (array.length >= count && result.length < count) {
			return SharedHelpers.uniqueRandomArray(array, count);
		}
		return result;
	}

	static formatDigits(num = 0, digits = 2) {
		return Number(Math.round(num + 'e' + digits) + 'e-' + digits);
	}

	static formatNumber(num = 0, digits = 2) {
		const { formatDigits } = SharedHelpers;
		return Lib.formatNumber(formatDigits(num, digits), digits, {
			minimumFractionDigits: digits,
			maximumFractionDigits: digits
		});
	}

	static convertToSlug(str = '') {
		return Lib.replaceUmlaute(str.toString().toLowerCase()).replace(/[^\w ]+/g, '').replace(/ +/g, '-');
	}
}

module.exports = SharedHelpers;
