export * from 'react-app/lib/helpers';

export function scrollToElement(id) {
	const elem = document.getElementById(id);
	if (!elem) return;
	const appBarElem = document.getElementById('appbar-header');
	const yOffset = appBarElem ? -appBarElem.clientHeight - 16 : 0;
	const top = elem.getBoundingClientRect().top + window.pageYOffset + yOffset;
	window.scrollTo({top});
}

export function isCatalog(str) {
	return typeof str === 'string' && str.startsWith('https://katalog.digital/master/?catalog=');
}
