import * as React from 'react';
import Typography from '@mui/material/Typography';
import ErrorBase from 'react-app/components/Error';
import AppContent from 'components/AppContent';
import Button from 'react-app/components/Button';
import { appData } from 'config/data.cjs';

const { messages } = appData;

function Error(props) {
	const {
		statusCode,
		title = messages.error.internal[Number.isFinite(statusCode) ? 'server' : 'client'],
		description = messages.error.internal.message,
		buttonTitle = messages.button.backHome
	} = props;

	return (
		<AppContent>
			<ErrorBase
				statusCode={statusCode}
				title={title}
				description={description}
			>
				<Typography textAlign="center">
					<Button color="primary" href="/">
						{buttonTitle}
					</Button>
				</Typography>
			</ErrorBase>
		</AppContent>
	);
}

export default React.memo(Error);
