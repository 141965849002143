import * as React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import NumberFormat from 'react-number-format';
import { Typography } from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { TextField } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Menu } from '@mui/material';
import MultiSelectField from '../MultiSelectField';
import FinalFormControl from '../FinalFormControl';
import FinalFormCheckbox from '../FinalFormCheckbox';
import FinalFormTextField from '../FinalFormTextField';
import FinalFormDateTime from '../FinalFormDateTime';
import Button from '../Button';
import Link from '../Link';
import { isRequired } from './helpers';
import formConfig from '../../../config/forms.cjs';

const { labels, options } = formConfig;

const NumberFormatComponent = React.forwardRef(function NumberFormatComponent(props, ref) {
	const { onChange, ...rest } = props;

	function handleValueChange(values) {
		onChange({
			target: {
				name: props.name,
				value: values.value
			}
		});
	}

	return (
		<NumberFormat
			decimalSeparator=","
			thousandSeparator="."
			{...rest}
			isNumericString
			getInputRef={ref}
			onValueChange={handleValueChange}
		/>
	);
});

function NumberField(props) {
	const { children, ...rest } = props;

	const InputProps = React.useMemo(() => ({
		inputComponent: NumberFormatComponent
	}), []);

	return (
		<TextField {...rest} InputProps={InputProps}>
			{children}
		</TextField>
	);
}

function FinalFormMultiSelectField(props) {
	return (
		<FinalFormTextField {...props} TextFieldComponent={MultiSelectField}/>
	);
}

export function SelectField(props) {
	const {
		component: Component = Menu,
		items = {},
		label,
		listLabel,
		allowEmptyValue = true,
		valueFromKey = false,
		MenuItemProps,
		required,
		name,
		...rest
	} = props;

	return (
		<Component
			type="select"
			select
			{...rest}
			name={name}
			label={label}
			required={required}
		>
			{allowEmptyValue && !required &&
				<MenuItem {...MenuItemProps} key={-1} value="">
					<em>{listLabel || label || labels[name] || 'Keine Auswahl'}</em>
				</MenuItem>
			}
			{Array.isArray(items) ? items.map((item = {}, key) => (
				<MenuItem {...MenuItemProps} key={key} value={valueFromKey ? key : item?.id ?? key}>
					{typeof item === 'string' ? item : item?.title || key}
				</MenuItem>
			)) : Object.entries(items).map(([id, item = {}], key) => (
				<MenuItem {...MenuItemProps} key={key} value={valueFromKey ? key : item?.id ?? id}>
					{typeof item === 'string' ? item : item?.title || id}
				</MenuItem>
			))}
		</Component>
	);
}

export const FormGridItem = React.memo(function FormGridItem(props) {
	const {
		children,
		size = 12,
		...rest
	} = props;

	return (
		<Grid
			size={size}
			{...rest}
		>
			{children}
		</Grid>
	);
});

export const FormField = React.memo(function FormField(props) {
	const {
		children,
		name,
		submitting,
		label,
		validate,
		...rest
	} = props;

	return (
		<Field
			validate={validate || (props.required ? isRequired : null)}
			name={name}
			disabled={submitting}
			label={label || labels?.[name]}
			{...rest}
		>
			{children}
		</Field>
	);
});
FormField.propTypes = {
	children: PropTypes.node,
	name: PropTypes.string.isRequired,
	label: PropTypes.node,
	submitting: PropTypes.bool
};

export const FormControl = React.memo(function FormControl(props) {
	const {
		children,
		validate,
		...rest
	} = props;

	return (
		<Field
			validate={validate || (props.required ? isRequired : null)}
			component={FinalFormControl}
			{...rest}
		>
			{children}
		</Field>
	);
});
FormControl.propTypes = {
	children: PropTypes.node.isRequired
};

export const FormHiddenField = React.memo(function FormHiddenField(props) {
	return (
		<Field
			{...props}
			component="input"
			type="hidden"
		/>
	);
});

export const FormTextField = React.memo(function FormTextField(props) {
	const {
		children,
		...rest
	} = props;

	return (
		<FormField
			component={FinalFormTextField}
			TextFieldComponent={TextField}
			type="text"
			margin="normal"
			fullWidth
			maxLength={100}
			{...rest}
		>
			{children}
		</FormField>
	);
});
FormTextField.propTypes = {
	children: PropTypes.node
};

export const FormSelectField = React.memo(function FormSelectField(props) {
	const {
		component = FormTextField,
		name,
		...rest
	} = props;

	return (
		<SelectField
			{...rest}
			name={name}
			component={component}
		/>
	);
});
FormSelectField.propTypes = {
	MenuItemProps: PropTypes.object
};

export const FormCheckbox = React.memo(function FormCheckbox(props) {
	const {
		children,
		...rest
	} = props;

	return (
		<FormField
			component={FinalFormCheckbox}
			type="checkbox"
			{...rest}
		>
			{children}
		</FormField>
	);
});
FormCheckbox.propTypes = {
	children: PropTypes.node
};

export const FormMultiSelectField = React.memo(function FormMultiSelectField(props) {
	const {
		children,
		...rest
	} = props;

	return (
		<FormField
			component={FinalFormMultiSelectField}
			TextFieldComponent={TextField}
			margin="normal"
			fullWidth
			{...rest}
		>
			{children}
		</FormField>
	);
});
FormMultiSelectField.propTypes = {
	children: PropTypes.node
};

export const FormDateTime = React.memo(function FormTextField(props) {
	const {
		children,
		...rest
	} = props;

	return (
		<FormField
			component={FinalFormDateTime}
			TextFieldComponent={TextField}
			margin="normal"
			fullWidth
			{...rest}
		>
			{children}
		</FormField>
	);
});
FormTextField.propTypes = {
	children: PropTypes.node
};

export const FormButton = React.memo(function FormButton(props) {
	const {children, sx, ...rest} = props;

	return (
		<Button
			fullWidth
			variant="contained"
			{...rest}
			sx={{
				marginTop: 2,
				...sx
			}}
		>
			{children}
		</Button>
	);
});

export const FormSubmitButton = React.memo(function FormSubmitButton(props) {
	const {children, ...rest} = props;

	return (
		<FormButton
			color="primary"
			type="submit"
			{...rest}
		>
			{children || labels.submitTitle}
		</FormButton>
	);
});

export const FormResetButton = React.memo(function FormResetButton(props) {
	const {children, ...rest} = props;

	return (
		<FormButton {...rest}>
			{children || labels.resetTitle}
		</FormButton>
	);
});

export const FormOfAddressFromField = React.memo(function FormOfAddressFromField(props) {
	return (
		<FormSelectField
			name="formOfAddress"
			items={options.formOfAddress}
			{...props}
		/>
	);
});

export const EmailFormField = React.memo(function EmailFormField(props) {
	return (
		<FormTextField
			name="email"
			{...props}
		/>
	);
});

export const SubjectFormField = React.memo(function SubjectFormField(props) {
	return (
		<FormTextField
			name="subject"
			{...props}
		/>
	);
});

export const MessageFormField = React.memo(function MessageFormField(props) {
	return (
		<FormTextField
			name="message"
			multiline
			maxLength={500}
			minRows={8}
			maxRows={16}
			{...props}
		/>
	);
});

export const PrivacyPolicyCheckbox = React.memo(function PrivacyPolicyCheckbox(props) {
	const {
		children,
		FormCheckboxProps,
		LinkProps,
		...rest
	} = props;

	return (
		<FormControl
			required
			name="privacyPolicy"
			{...rest}
		>
			<FormCheckbox
				name="privacyPolicy"
				size="small"
				{...FormCheckboxProps}
				label={
					children || (
						<>
							Ich habe die{' '}
							<Link
								target="_blank"
								href="/datenschutz"
								color="primary"
								{...LinkProps}
							>
								Datenschutzbestimmungen
							</Link>
							{' '}zur Kenntnis genommen.
						</>
					)
				}
			/>
		</FormControl>
	);
});

export const RequiredHint = React.memo(function RequiredHint(props) {
	const { label = labels.required, sx, ...rest } = props;

	return (
		<Typography
			{...rest}
			sx={{
				textAlign: 'right',
				marginTop: 2,
				...sx
			}}
		>
			<small>{label}</small>
		</Typography>
	);
});

export const FormNumberField = React.memo(function FormNumberField(props) {
	const {
		children,
		...rest
	} = props;

	return (
		<FormTextField
			TextFieldComponent={NumberField}
			maxLength={10}
			{...rest}
		>
			{children}
		</FormTextField>
	);
});
FormNumberField.propTypes = {
	children: PropTypes.node
};
