import * as React from 'react';
import PropTypes from 'prop-types';
import { useDelay } from '../../hooks';

function Delay(props) {
	const {
		children,
		delay,
		initialOpen
	} = props;

	const doRender = useDelay(delay, initialOpen);

	return doRender ? children : null;
}

Delay.propTypes = {
	children: PropTypes.node,
	delay: PropTypes.number,
	initialOpen: PropTypes.bool
};

export default React.memo(Delay);
