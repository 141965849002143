import * as React from 'react';
import PropTypes from 'prop-types';
import FinalFormTextField from '../FinalFormTextField';

const convertDate = (strDate, type, step) => {
	try {
		if (strDate) {
			const date = new Date(new Date(strDate).toString().split('GMT')[0]+' UTC').toISOString();
			switch (type) {
				case 'date': {
					return date.substr(0, 10);
				}
				case 'time': {
					return date.substr(11, step <= 1 ? 8 : 5);
				}
				case 'datetime-local': {
					return date.substr(0, step <= 1 ? 19 : 16);
				}
			}
		}
		return strDate;
	} catch (err) {
		console.error(err.message);
	}
};

function FinalFormDateTime(props) {
	const {
		input: {
			value,
			type = 'datetime-local',
			...restInput
		},
		InputLabelProps,
		min,
		max,
		step,
		inputProps,
		...rest
	} = props;

	return (
		<FinalFormTextField
			type={type}
			{...props}
			input={{
				...restInput,
				value: convertDate(value, type, step),
				...(min && {min: convertDate(min, type)}),
				...(max && {max: convertDate(max, type)})
			}}
			inputProps={{
				step,
				...inputProps
			}}
			InputLabelProps={{
				shrink: true,
				...InputLabelProps
			}}
		/>
	);
}

FinalFormDateTime.propTypes = {
	type: PropTypes.oneOf(['date', 'time', 'datetime-local']),
	step: PropTypes.number
};

export default React.memo(FinalFormDateTime);
