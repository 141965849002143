import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { Typography } from '@mui/material';

const DEV = process.env.NODE_ENV !== 'production';

const useClasses = createClasses((theme) => ({
	root: {
		position: 'relative',
		width: '100%',
		minHeight: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	container: {
		padding: theme.spacing(2),
		maxWidth: 1200,
		margin: '0 auto'
	},
	dev: {
		color: theme.vars.palette.common.white,
		background: theme.vars.palette.error.dark
	},
	headline: {
		whiteSpace: 'pre-line',
		[theme.breakpoints.down('tablet')]: {
			fontSize: '1.625rem'
		}
	},
	stack: {
		whiteSpace: 'pre-line',
		[theme.breakpoints.down('tablet')]: {
			fontSize: '1rem'
		}
	}
}), {
	name: 'RaErrorBoundaryFallback',
	mergeClassName: false
});

function ErrorBoundaryFallback(props) {
	const {
		classes: classesProp,
		className,
		children,
		componentStack,
		error,
		devMode = DEV
	} = props;

	const classes = useClasses(props);

	return (
		<div
			className={clsx(
				classes.root,
				devMode && classes.dev,
				className
			)}
		>
			<div className={classes.container}>
				{devMode ?
					<>
						<Typography
							gutterBottom
							className={classes.headline}
							color="inherit"
							variant="h3"
						>
							{error.toString().trim()}
						</Typography>
						<Typography
							className={classes.stack}
							color="inherit"
							variant="h6"
							component="p"
						>
							{componentStack}
						</Typography>
					</>
					:
					children
				}
			</div>
		</div>
	);
}

ErrorBoundaryFallback.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	componentStack: PropTypes.node,
	error: PropTypes.object,
	devMode: PropTypes.bool
};

export default React.memo(ErrorBoundaryFallback);
