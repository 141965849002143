import * as React from 'react';
import PropTypes from 'prop-types';
import { Link as  MuiLink } from '@mui/material';
import Link, { transformColor } from '../Link';

const NavLink = React.forwardRef(function NavLink({LinkComponent = Link, ...props}, ref) {
	const { href: hrefProp, to, noLinkStyle = true, exact, ...rest } = props;

	const href = hrefProp || to;
	const isExternalLink = props.target === '_blank' || href?.startsWith?.('/') === false;

	if (!href) {
		return (
			<Link component="span" {...rest} noLinkStyle={noLinkStyle} ref={ref}/>
		);
	}

	return (
		isExternalLink ? (
			<Link {...rest} noLinkStyle={noLinkStyle} href={href} ref={ref}/>
		) : noLinkStyle ? (
			<LinkComponent {...rest} exact={exact} to={href} ref={ref}/>
		) : (
			<MuiLink component={LinkComponent} {...rest} exact={exact} color={transformColor(props.color)} to={href} ref={ref}/>
		)
	);
});

NavLink.propTypes = {
	href: PropTypes.string,
	exact: PropTypes.bool,
	to: PropTypes.string,
	noLinkStyle: PropTypes.bool
};

export default React.memo(NavLink);
