import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from '../../styles';

const useClasses = createClasses((theme) => ({
	root: {
		color: theme.vars.palette.SnackbarContent.color,
		backgroundColor: theme.vars.palette.SnackbarContent.bg,
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		padding: theme.spacing(1.5, 2),
		borderRadius: `${theme.shape.borderRadius}px`,
		[theme.breakpoints.up('md')]: {
			minWidth: 288,
			maxWidth: 568
		},
		[theme.breakpoints.down('tablet')]: {
			flexGrow: 1
		}
	}
}), {
	name: 'RaSnackbarContent'
});

const SnackbarContent = React.forwardRef(function SnackbarContent(props, ref) {
	const {
		classes: classesProp,
		className,
		children,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<div
			{...rest}
			ref={ref}
			className={classes.root}
		>
			{children}
		</div>
	);
});

SnackbarContent.displayName = 'SnackbarContent';

SnackbarContent.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node.isRequired
};

export default React.memo(SnackbarContent);
