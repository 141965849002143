const breakpoints = {
	values: {
		xs: 0,
		phone: 480,
		sm: 600,
		tablet: 768,
		md: 960,
		laptop: 1024,
		lg: 1280,
		desktop: 1440,
		xl: 1909
	}
};

breakpoints.keys = Object.keys(breakpoints.values);

export default breakpoints;
