import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Img from 'react-app/components/Img';
import Headline from 'components/Headline';
import Dialog from 'react-app/components/Dialog';
import Expandable from 'react-app/components/Expandable';
import ExpandableHeader from 'react-app/components/ExpandableHeader';
import Button from 'components/Button';
import ShopButton from 'components/ShopButton';
import Link from 'components/Link';
import PersonIcon from '@mui/icons-material/Person';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import { Cookies, LocalStorage, toBoolean } from 'lib/helpers';
import loadable from '@loadable/component';
import { useDidMount } from 'react-app/hooks';
import { usePageData } from 'react-app/context/page-data';
import { useNavigate, useLocation } from 'react-router-dom';
import { useBrowser } from 'react-app/context/browser';
import { useUser } from 'context/user';
import { useCookieConsent } from 'lib/cookie-consent';
import { useGa } from 'lib/ga';
import { appData } from 'config/data.cjs';
import {
	retail_image,
	wholesale_image
} from 'config/images';

const CookieConsent = loadable(() => import('react-app/components/CookieConsent'));

const ActionAreaProps = {
	disableRipple: true
};

const cookieSettings = {
	secure: true,
	expires: 365
};

function Content(props) {
	const {
		children,
		image,
		title,
		subtitle,
		color,
		onClick,
		userType,
		buttonText,
		shopButtonText,
		expandable
	} = props;

	const headline = (
		<Headline title={title}/>
	);

	const content = (
		<Stack gap={1}>
			<Headline
				mt={expandable ? 0 : -2}
				mb={expandable ? 0 : 1}
				subtitle={subtitle}
			/>
			{children}
		</Stack>
	);

	return (
		<Stack width={{sm: '50%'}} gap={expandable ? 1 : 2} flex={1}>
			<Img {...image} alt={title} showPlaceholder fullWidth rounded/>

			{expandable ? (
				<Expandable >
					<ExpandableHeader
						actionArea
						ActionAreaProps={ActionAreaProps}
						component={Stack}
						direction="row"
						alignItems="center"
					>
						{headline}
					</ExpandableHeader>
					{content}
				</Expandable>
			) : (
				<>
					{headline}
					{content}
				</>
			)}

			<Stack
				gap={expandable ? .75 : 1.5}
				mt="auto"
				display="grid"
				alignItems="center"
				justifyContent="center"
			>
				<Button
					sx={{mt: 1}}
					size={expandable ? 'medium' : 'large'}
					variant="contained"
					color={color}
					onClick={onClick}
					startIcon={<PersonIcon/>}
					endIcon={<ArrowIcon/>}
				>
					{buttonText}
				</Button>
				<ShopButton
					size="small"
					variant="text"
					color={color}
					userType={userType}
				>
					{shopButtonText}
				</ShopButton>
			</Stack>
		</Stack>
	);
}

const DialogContentProps = {
	sx: {overflowX: 'hidden'}
};

function UserTypeDialog(props) {
	const {
		classes: classesProp,
		className,
		open,
		...rest
	} = props;

	const [{
		userDialogOpen,
		userType,
		isRetail
	}, {
		setUserType,
		setUserDialog
	}] = useUser();

	const browser = useBrowser();
	const { isWidthUp, breakpoint } = browser;
	const mdUp = isWidthUp('md', breakpoint);

	const navigate = useNavigate();
	const { search } = useLocation();
	const [ pageData = {} ] = usePageData();
	const { contact: { map }, links } = appData;

	const { initGa } = useGa();
	const { handleCookieConsentClose } = useCookieConsent();

	const retailColor = isRetail ? 'primary' : 'secondary';
	const wholesaleColor = isRetail ? 'secondary' : 'primary';
	const pageUserType = pageData.userType;

	const [ hideDialog, setHideDialog ] = React.useState(!open);

	function handleClick(type) {
		return () => {
			if (pageUserType) {
				window.addEventListener('pageChangeEnd', () => {
					setUserType(type);
				}, { once: true });
				navigate('/');
				setUserDialog(false);
			} else {
				setUserType(type);
			}
		};
	}

	function handleClose() {
		Cookies.set('userType', userType, cookieSettings);
		setUserDialog(false);
	}

	function handleHideDialogToggle(event) {
		Cookies.set('userTypeDialogHidden', event.target.checked, cookieSettings);
		setHideDialog(event.target.checked);
	}

	React.useEffect(() => {
		const userTypeDialogHidden = toBoolean(Cookies.get('userTypeDialogHidden'));
		setHideDialog(userTypeDialogHidden);

		const handleUserType = (pageUserType) => {
			if (!pageUserType) {
				const userTypeCookie = Cookies.get('userType');
				if (!userTypeCookie && !userTypeDialogHidden) {
					setUserDialog(true);
				} else if (userTypeCookie !== userType) {
					setUserType(userTypeCookie);
				}
			} else if (pageUserType !== userType) {
				setUserType(pageUserType, false);
			}
		};

		const queryParams = new URLSearchParams(search);
		const userTypeParam = queryParams.get('ut') || queryParams.get('userType');
		if (userTypeParam) {
			LocalStorage.set('userType', Cookies.get('userType'));
			Cookies.set('userType', userTypeParam, cookieSettings);
			window.addEventListener('pageChangeEnd', (event) => {
				const lastUserType = LocalStorage.get('userType');
				if (lastUserType) {
					Cookies.set('userType', lastUserType, cookieSettings);
				} else {
					Cookies.remove('userType');
				}
				LocalStorage.remove('userType');
				handleUserType(event?.detail?.page?.userType);
			}, { once: true });
		}

		handleUserType(pageUserType);
	}, [userType, pageUserType, setUserType, search, setUserDialog, open]);

	useDidMount(initGa);

	return (
		<>
			<Dialog
				maxWidth="md"
				scroll="body"
				title="Willkommen bei Holz Weidauer"
				subtitle="Ihr Holzfachhandel in Lauter & Chemnitz"
				{...rest}
				onClose={handleClose}
				DialogContentProps={DialogContentProps}
				open={open}
			>
				<Stack
					direction={{sm: 'row'}}
					rowGap={{xs: 2, tablet: 3, md: 4}}
					columnGap={{xs: 1, tablet: 1.5, md: 2}}
					maxWidth="100%"
				>
					<Content
						title="Einzelhandel für Heimwerker und Profis"
						subtitle="Ein Projekt – Ein Einkauf"
						buttonText="weiter als Privatkunde"
						shopButtonText="zu unserem Online-Shop"
						image={retail_image}
						color={retailColor}
						userType="retail"
						expandable={!mdUp}
						onClick={handleClick('retail')}
					>
						<Typography variant="body2">
							Wer kennt das nicht: Das Bauprojekt steht, die Materialplanung auch – und dann beginnt das Gerenne von einem Händler zum anderen,
							bis man alles zusammen hat. Tun Sie sich das nicht an, sondern kommen Sie einfach gleich zu uns
							nach <Link color={retailColor} href={map.lauter2}>Lauter / Erzgebirge</Link> oder
							nach <Link color={retailColor} href={map.chemnitz}>Chemnitz-Rabenstein</Link>!
						</Typography>
						<Typography variant="body2">
							In den Sortimentsbereichen Bauen mit Holz, Gartenwelt, Türen neu erleben und Faszination Fußboden halten wir für Sie alles bereit,
							was Sie zur Umsetzung Ihres Projektes benötigen.
						</Typography>
						<Typography variant="body2">
							Für Holzhandwerker – also die Profis unter Ihnen – steht in Chemnitz-Rabenstein überdies unser großer Holzabholmarkt bereit.
							Und für die Einweihungsfeier für Ihr Projekt finden Sie in unserer 250 Quadratmeter großen Weber-Grill-World in Chemnitz-Rabenstein
							sicher auch gleich noch den passenden Grill.
						</Typography>
					</Content>

					<Content
						title="Großhandel für das Holzhandwerk"
						subtitle="2 Standorte und dennoch alles aus einer Hand"
						buttonText="weiter als Firmenkunde"
						shopButtonText="zu unserem Profi-Shop"
						image={wholesale_image}
						color={wholesaleColor}
						userType="wholesale"
						expandable={!mdUp}
						onClick={handleClick('wholesale')}
					>
						<Typography variant="body2">
							Mit unseren beiden Standorten in <Link color={wholesaleColor} href={map.lauter}>Lauter / Erzgebirge</Link> und
							im <Link color={wholesaleColor} href={map.chemnitz}>Chemnitzer Ortsteil Rabenstein</Link> sind wir D E R Profimarkt in Sachen Holz.
						</Typography>
						<Typography variant="body2">
							Holzhandwerker werden bei uns in den Bereichen Konstruktiver Holzbau, Holz für Garten und Terrasse, Tischlerware vom Produzenten,
							Bauelemente und Objektarbeit, Fußböden, Wand und Decke, Platten und Holzwerkstoffe fündig. Darüber hinaus bieten wir über
							unseren <Link color={wholesaleColor} href={links.tib}>Technischen Industriebedarf (TIB)</Link> Werkzeuge und Maschinen, Zimmereibedarf, Bauchemie und Farbe sowie Antriebstechnik an.
						</Typography>
						<Typography variant="body2">
							Ihr Vorteil: Sie bekommen alles aus einer Hand – auch in großen Mengen! Mehr als 3.000 Holz-Handwerkskunden in Sachsen und
							Thüringen haben diesen Vorteil bereits für sich erkannt. Und wann gehören Sie dazu?
						</Typography>
					</Content>
				</Stack>
				<Stack alignItems="center" marginTop={1}>
					<FormControlLabel
						sx={{'& .MuiFormControlLabel-label': {fontSize: '0.875rem'}}}
						control={<Checkbox size="small" checked={hideDialog} onChange={handleHideDialogToggle} />}
						label="nicht mehr anzeigen"
					/>
				</Stack>
			</Dialog>
			{(pageUserType || userDialogOpen !== null) && !open && (
				<CookieConsent
					LinkComponent={Link}
					linkColor="primary"
					onClose={handleCookieConsentClose}
					onAccept={initGa}
				/>
			)}
		</>
	);
}

UserTypeDialog.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	open: PropTypes.bool,
	defaultOpen: PropTypes.bool
};

export default React.memo(UserTypeDialog);
