import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import ActionArea from '../ActionArea';
import CardHeader from '../CardHeader';

const useClasses = createClasses((theme) => ({
	root: {
		flexGrow: 1
	},
	expandIcon: {
		display: 'flex',
		color: 'inherit',
		margin: theme.spacing(-.75, 0),
		marginLeft: 'auto',
		marginRight: theme.spacing(-1),
		alignSelf: 'center',
		transition: theme.transitions.create('transform', {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.comlex
		})
	},
	expanded: {
		transform: 'rotate(-180deg)'
	},
	actionArea: {
		textAlign: 'left',
		justifyContent: 'flex-start'
	}
}), {
	name: 'RaExpandableHeader'
});

function ExpandableHeader(props) {
	const {
		classes: classesProp,
		className,
		children,
		onChange,
		expanded,
		expandIcon = <ExpandMoreIcon/>,
		actionArea,
		disableIconButton = actionArea,
		IconButtonProps,
		ActionAreaProps,
		expandable = true,
		component: Component = CardHeader,
		...rest
	} = props;

	const classes = useClasses(props);

	const headerClasses = clsx(
		classes.expandIcon,
		expanded && expandable && classes.expanded
	);

	const header = (
		<Component {...rest} className={classes.root}>
			{children}

			{expandable ? (
				disableIconButton ? (
					<div
						style={{marginRight: 0}}
						className={headerClasses}
					>
						{expandIcon}
					</div>
				) : (
					<IconButton
						size="small"
						className={headerClasses}
						onClick={onChange}
						{...IconButtonProps}
					>
						{expandIcon}
					</IconButton>
				)
			) : null}
		</Component>
	);

	return (
		actionArea && expandable ? (
			<ActionArea
				onClick={onChange}
				{...ActionAreaProps}
				className={clsx(classes.actionArea, ActionAreaProps?.className)}
			>
				{header}
			</ActionArea>
		) : (
			header
		)
	);
}

ExpandableHeader.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	expandIcon: PropTypes.node,
	onChange: PropTypes.func,
	expanded: PropTypes.bool,
	expandable: PropTypes.bool,
	actionArea: PropTypes.bool,
	disableIconButton: PropTypes.bool,
	IconButtonProps: PropTypes.object,
	ActionAreaProps: PropTypes.object,
	component: PropTypes.elementType
};

export default React.memo(ExpandableHeader);
