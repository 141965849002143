import types from './types.cjs';
import { getUserTypeData } from 'lib/shared-helpers.cjs';

export const initialState = {
	userType: 'retail',
	isRetail: true,
	isWholesale: false,
	userDialogOpen: null
};

export { getUserTypeData };

export default function userReducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.SET_USER_TYPE: {
			return {
				...state,
				...getUserTypeData(action.data || initialState.userType),
				userDialogOpen: false
			};
		}
		case types.SET_USER_DIALOG: {
			return {
				...state,
				userDialogOpen: action.data
			};
		}
	}

	return state;
}

userReducer.initialState = initialState;
