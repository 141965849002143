import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from 'styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Img from 'react-app/components/Img';
import Parser from 'react-app/components/HtmlParser';
import Label from 'react-app/components/Label';
import ShopIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import ZoomIcon from '@mui/icons-material/ZoomOutMap';
import { useDialog } from 'react-app/components/DialogProvider';
import { useMemoCallback } from 'react-app/hooks';
import { isPdf, isImage, isExternalLink, isCatalog } from 'lib/helpers';
import Button from 'components/Button';
import NewsDialog from 'components/News/NewsDialog';
import EventDialog from 'components/Events/EventDialog';
import { useEventBooking } from 'components/Events/EventBooking';
import TimeAgo from 'javascript-time-ago';
import de from 'javascript-time-ago/locale/de';
import { news as sizes } from 'config/sizes.cjs';
import { useUser } from 'context/user';

TimeAgo.addLocale(de);
const timeAgo = new TimeAgo('de-DE');

const useClasses = createClasses((theme, props) => ({
	root: {
		maxWidth: '100%',
		flexWrap: 'wrap',
		flexShrink: 0,
		outline: 'none',
		padding: theme.spacing(1.5, 0)
	},
	seen: {},
	last: {},
	divider: {},
	listItemText: {},
	heading: {
		lineHeight: 1.3,
		fontSize: theme.typography.pxToRem(15)
	},
	subheading: {
		color: theme.vars.palette.text.secondary,
		marginBottom: theme.spacing(1.5)
	},
	content: {},
	message: {
		marginBottom: theme.spacing(1.5),
		'& p': {
			lineHeight: 1.5,
			margin: theme.spacing(1, 0)
		},
		'& > *:first-child': {
			marginTop: 0
		},
		'& > *:last-child': {
			marginBottom: 0
		},
		'& ul, & ol': {
			paddingLeft: theme.spacing(2),
			'& > li': {
				lineHeight: 1.5
			}
		},
		'& small': {
			display: 'block'
		}
	},
	spacing: {
		...theme.mixins.gutters('marginX')
	},
	badge: {
		top: theme.spacing(.5),
		right: theme.spacing(-.5)
	},
	image: {
		overflow: 'hidden',
		backgroundColor: theme.vars.palette.background.placeholder,
		maxWidth: '100%',
		marginBottom: theme.spacing(1.5),
		'& > img': {
			width: '100%',
			height: 'auto',
			objectFit: 'cover',
			objectPosition: 'center'
		}
	}
}), {
	name: 'NotifyListItem'
});

export const NotifyListItem = React.forwardRef(function NotifyListItem(props, ref) {
	const {
		classes: classesProp,
		className,
		link: linkProp,
		title,
		subtitle,
		contentHtml: html,
		messageHtml,
		publicFrom,
		divider,
		isPublic,
		isUpcoming,
		isOutdated,
		seen,
		onClick,
		imageSrc: imageSrcProp,
		imageSrcSet,
		iframeSrc,
		isVideoSrc,
		youtubeParams,
		ListItemProps,
		url,
		shopLink: retailShopLink,
		profiShopLink,
		videoButton,
		event,
		isEvent,
		category,
		soldOut,
		showLabel
	} = props;

	const classes = useClasses(props);

	const [ {isRetail = true} ] = useUser();
	const [dialogOpen, setDialogOpen] = React.useState(false);

	const link = youtubeParams?.iframeSrc || linkProp;
	const imageSrc = imageSrcProp || youtubeParams?.imageSrc;
	const shopLink = isRetail ? retailShopLink : profiShopLink;

	const {
		isBookable,
		button: signUpButton,
		dialog: signUpDialog
	} = useEventBooking({
		...props,
		ButtonProps: {
			fullWidth: !(dialog || link || html)
		}
	});

	const handleDialogOpen = useMemoCallback(() => {
		setDialogOpen(true);
	});

	const handleDialogClose = useMemoCallback(() => {
		setDialogOpen(false);
	});

	const linkIsExtern = isExternalLink(link);
	const dialog = !html && (isVideoSrc || isPdf(link) || isCatalog(link) || isImage(link));
	const { openDialog } = useDialog();

	const ButtonProps = dialog ? {
		title,
		onClick: openDialog,
		'data-src': link
	} : null;

	const DialogComponewnt = isEvent ? EventDialog : NewsDialog;

	return (
		<>
			<ListItem
				ref={ref}
				component="div"
				{...ListItemProps}
				className={clsx(
					classes.root,
					!divider && classes.last,
					seen && classes.seen,
					className
				)}
			>
				<ListItemText className={classes.listItemText} disableTypography>
					{title && (
						<Typography
							variant="subtitle2"
							component="h3"
							className={clsx(
								classes.spacing,
								classes.heading
							)}
						>
							{title}
						</Typography>
					)}
					<Typography
						variant="subtitle2"
						component="p"
						className={clsx(
							classes.spacing,
							classes.subheading
						)}
					>
						<Badge
							classes={{
								badge: classes.badge
							}}
							badgeContent={+!seen}
							variant="dot"
							color="primary"
						>
							{timeAgo.format(new Date(publicFrom))}
						</Badge>
						{(isUpcoming || isOutdated || !isPublic) &&
							<Label
								style={{marginLeft: !seen ? 16 : null}}
								color={isUpcoming && isPublic ? 'info' : 'error'}
							>
								{`${isUpcoming && isPublic ? 'noch ' : ''}nicht ${isOutdated && isPublic ? 'mehr ' : ''} öffentlich`}
							</Label>
						}
					</Typography>
					{imageSrc &&
						<Img
							className={clsx(
								classes.image,
								classes.spacing
							)}
							src={imageSrcSet?.xs || imageSrc}
							width={sizes.width.xs}
							height={sizes.width.xs / sizes.aspectRatio}
							showPlaceholder
							rounded
						/>
					}
					<Typography
						variant="body2"
						component="div"
						className={clsx(
							classes.spacing,
							classes.content
						)}
					>
						<Parser
							className={classes.message}
							html={messageHtml}
						/>
						<Stack gap={{xs: 1, sm: 2, md: 3}} justifyContent="center" flexDirection="row">
							{isBookable ? (
								<>
									{signUpDialog}
									{signUpButton}
								</>
							) : shopLink ? (
								<Button
									fullWidth
									color="primary"
									size="small"
									to={shopLink}
									startIcon={<ShopIcon/>}
								>
									zum Shop
								</Button>
							) : null}
							{(dialog || link || html) ? (
								<Button
									fullWidth
									color={shopLink || isBookable ? 'default' : 'primary'}
									size="small"
									to={html ? null : link}
									onClick={html ? handleDialogOpen : linkIsExtern ? null : onClick}
									startIcon={<InfoIcon/>}
									{...ButtonProps}
								>
									{isVideoSrc && !html ? 'Video ansehen' : 'mehr erfahren' }
								</Button>
							) : (
								<Button
									fullWidth
									size="small"
									onClick={openDialog}
									data-src={imageSrc}
									data-title={title}
									startIcon={<ZoomIcon/>}
									{...ButtonProps}
								>
									Große Ansicht
								</Button>
							)}
						</Stack>
					</Typography>
				</ListItemText>
			</ListItem>
			{divider &&
				<Divider className={classes.divider}/>
			}
			{html &&
				<DialogComponewnt
					open={dialogOpen}
					onClose={handleDialogClose}
					contentHtml={html}
					imageSrc={imageSrc}
					title={title}
					subtitle={subtitle}
					link={link}
					shopLink={shopLink}
					url={url}
					isVideoSrc={isVideoSrc}
					iframeSrc={iframeSrc}
					youtubeParams={youtubeParams}
					publicFrom={publicFrom}
					videoButton={videoButton}
					{...isEvent && {
						event,
						isEvent,
						category,
						soldOut,
						showLabel
					}}
				/>
			}
		</>
	);
});

NotifyListItem.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	link: PropTypes.string,
	id: PropTypes.string,
	title: PropTypes.string,
	html: PropTypes.string,
	imageSrc: PropTypes.string,
	imageSrcSet: PropTypes.object,
	publicFrom: PropTypes.number,
	divider: PropTypes.bool,
	isPublic: PropTypes.bool,
	isUpcoming: PropTypes.bool,
	onClick: PropTypes.func,
	ListItemProps: PropTypes.object
};

const NotifyList = React.forwardRef(function NotifyList(props, ref) {
	const { children, ...rest } = props;

	return (
		Array.isArray(children) && children.map((child, key) => (
			<NotifyListItem ref={ref} divider={key < children.length - 1} key={key} {...child} {...rest}/>
		))
	);
});

NotifyList.propTypes = {
	children: PropTypes.array.isRequired
};

export default React.memo(NotifyList);
