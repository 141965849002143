import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const defaultProps = {
	type: 'default'
};

const useClasses = createClasses((theme, {fullWidth, type = defaultProps.type, onClose: dismissable, rounded = true}) => {
	const { darkMode } = theme.config;
	const { alpha } = theme.helpers;
	const variantMixin = (variant => ({
		borderColor: theme.vars.palette[variant][darkMode ? 'main' : 'dark'],
		backgroundColor: alpha(`${variant}-main`, .16),
		color: theme.vars.palette[variant][darkMode ? 'main' : 'dark']
	}));

	return {
		root: {
			position: 'relative',
			backgroundColor: theme.config.darkMode ? alpha('grey-100', .16) : theme.vars.palette.grey[300],
			borderLeft: `solid 4px ${theme.vars.palette.grey[theme.config.darkMode ? 400 : 600]}`,
			padding: theme.spacing(.75, 1),
			color: theme.vars.palette.grey[theme.config.darkMode ? 300 : 700],
			boxShadow: theme.shadows[1],
			...(rounded && {
				borderRadius: `${theme.shape.borderRadius}px`
			})
		},
		default: {},
		error: {
			...(variantMixin('error'))
		},
		success: {
			...(variantMixin('success'))
		},
		info: {
			...(variantMixin('info'))
		},
		warning: {
			...(variantMixin('warning'))
		},
		primary: {
			...(variantMixin('primary'))
		},
		secondary: {
			...(variantMixin('secondary'))
		},
		blockquote: {
			position: 'relative',
			padding: theme.spacing(.75, 3.5),
			'&:before, &:after': {
				position: 'absolute',
				display: 'inline-flex',
				fontSize: '4rem',
				verticalAlign: 'middle',
				lineHeight: '1.5rem',
				opacity: .6,
				fontWeight: 300
			},
			'&:before': {
				left: theme.spacing(1.5),
				top: theme.spacing(3),
				content: '"“"'
			},
			'&:after': {
				right: theme.spacing(1.5),
				top: 'auto',
				bottom: theme.spacing(-1.75),
				content: '"”"'
			}
		},
		icon: {
			opacity: .9,
			display: 'inline-flex',
			padding: theme.spacing(.75, 0),
			color: 'inherit',
			'& > .MuiSvgIcon-fontSizeSmall': {
				marginTop: theme.spacing(.25)
			}
		},
		container: {
			display: 'flex',
			marginRight: 'auto',
			...(fullWidth && {
				marginRight: 0
			}),
			...(type === 'blockquote' && {
				fontStyle: 'italic'
			})
		},
		content: {
			flexWrap: 'wrap',
			display: 'flex',
			lineHeight: 1.4,
			minHeight: theme.spacing(4.5),
			padding: theme.spacing(1)
		},
		action: {
			padding: theme.spacing(.5, 1),
			justifyContent: 'center',
			display: 'inline-flex',
			...(dismissable && {
				[theme.breakpoints.down('phone')]: {
					paddingLeft: theme.spacing(3),
					flexBasis: '100%',
					paddingRight: 0
				}
			}),
			...(!fullWidth && {
				paddingLeft: theme.spacing(3)
			})
		},
		sticky: {
			position: 'sticky',
			top: 'var(--app-bar-height)'
		},
		fullWidth: {
			zIndex: theme.zIndex.appBar - 1,
			width: '100%',
			marginLeft: 0,
			marginRight: 0,
			...(dismissable && {
				paddingLeft: theme.spacing(4),
				[theme.breakpoints.down('phone')]: {
					paddingLeft: theme.spacing(1),
					justifyContent: 'flex-start'
				}
			})
		},
		dismissable: {
			paddingRight: theme.spacing(4)
		},
		closeIcon: {
			position: 'absolute',
			padding: 0,
			top: 9,
			right: theme.spacing(1)
		},
		noBorder: {
			border: 'none'
		}
	};
}, {
	name: 'RaAlertBox',
	mergeClassName: false
});

function AlertBox(props) {
	const {
		classes: classesProp,
		className,
		children,
		type = defaultProps.type,
		icon,
		action,
		fullWidth,
		sticky,
		onClose,
		CloseButtonProps,
		noBorder,
		rounded,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Typography
			variant="subtitle2"
			component="div"
			justifyContent="center"
			alignSelf="center"
			flexWrap="wrap"
			display="flex"
			{...rest}
			className={clsx(
				classes.root,
				classes[type],
				fullWidth && classes.fullWidth,
				sticky && classes.sticky,
				noBorder && classes.noBorder,
				onClose && classes.dismissable,
				className
			)}
		>
			<div className={classes.container}>
				{icon && (
					<div className={classes.icon}>
						{icon}
					</div>
				)}
				<div className={classes.content}>
					{children}
				</div>
			</div>
			{action && (
				<div className={classes.action}>
					{action}
				</div>
			)}
			{onClose && (
				<div className={clsx(classes.icon, classes.closeIcon)}>
					<IconButton
						{...CloseButtonProps}
						color="inherit"
						size="small"
						onClick={onClose}
						aria-label="schließen"
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>
			)}
		</Typography>
	);
}

AlertBox.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	icon: PropTypes.node,
	action: PropTypes.node,
	fullWidth: PropTypes.bool,
	sticky: PropTypes.bool,
	onClose: PropTypes.func,
	CloseButtonProps: PropTypes.object,
	noBorder: PropTypes.bool,
	rounded: PropTypes.bool,
	type: PropTypes.oneOf(['default', 'error', 'success', 'warning', 'info', 'primary', 'secondary', 'blockquote'])
};

export default React.memo(AlertBox);
