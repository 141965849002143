import * as React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { BROWSER } from '../..//lib/helpers';
import nprogress from 'nprogress';

if (BROWSER) {
	nprogress.configure({ showSpinner: false });
}

const defaultProps = {
	color: '#fff',
	height: 2
};

export { nprogress };

function NProgress({color = defaultProps.color, height = defaultProps.height}) {
	const styles = React.useMemo(() => ({
		'#nprogress': {
			pointerEvents: 'none',
			'& .bar': {
				background: color,
				position: 'fixed',
				zIndex: 10000,
				top: 0,
				left: 0,
				width: '100%',
				height
			},
			'& .peg': {
				display: 'block',
				position: 'absolute',
				right: 0,
				width: 100,
				height: '100%',
				boxShadow: `0 0 10px ${color}, 0 0 5px ${color}`,
				opacity: 1,
				WebkitTransform: 'rotate(3deg) translate(0px, -4px)',
				msTransform: 'rotate(3deg) translate(0px, -4px)',
				transform: 'rotate(3deg) translate(0px, -4px)'
			}
		}
	}), [color, height]);

	return <Global styles={styles}/>;
}

NProgress.propTypes = {
	color: PropTypes.string,
	height: PropTypes.number
};

export default React.memo(NProgress);
