/* global WEBPACK_GENERATE_SW */

import 'lib/nonce';
import 'react-app/assets/fonts/hind-siliguri';
import { cacheOptions } from 'styles';
import renderer from 'react-app/webpack/client';
import smoothscroll from 'smoothscroll-polyfill';
import { loadableReady } from '@loadable/component';
import routes from 'routes';

smoothscroll.polyfill();

loadableReady(() => {
	if (WEBPACK_GENERATE_SW) {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/sw.js').catch(console.error);
		}
	}
	renderer({
		routes,
		cacheOptions
	});
});
