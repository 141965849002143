import types from './types.cjs';
import { initialState } from './reducer';

export function setUserType(data = initialState.userType, updateCookie = true) {
	if (updateCookie) {
		document.cookie = `userType=${data};max-age=1209600;path=/`;
	}

	return {
		type: types.SET_USER_TYPE,
		data
	};
}

export function setUserDialog(data = initialState.userDialogOpen) {
	return {
		type: types.SET_USER_DIALOG,
		data
	};
}
