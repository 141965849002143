import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from '../../styles';
import equals from 'equals';
import { Form } from 'react-final-form';
import { Grid2 as Grid } from '@mui/material';
import { useForkRef } from '@mui/material/utils';
import MessageSnackbar from '../MessageSnackbar';
import LoadingSnackbar from '../LoadingSnackbar';
import { useDidMount, useAsyncSetState } from '../../hooks';
import { getComponentState, setComponentState, deepmerge, fetchJson } from '../../lib/helpers';
import { validate } from './helpers';
import formConfig from '../../../config/forms.cjs';

const useClasses = createClasses((theme) => ({
	root: {
		...theme.mixins.flexContainer,
		position: 'relative',
		margin: 'auto'
	},
	form: {},
	textField: {},
	errorBox: {},
	successBox: {}
}), {
	name: 'FinalForm'
});

const DefaultFinalFormComponent = React.memo(function FinalFormComponent(props) {
	const {
		classes,
		children,
		render,
		renderForm,
		formRef,
		formPropsRef,
		FormComponent,
		FormWrapper = React.Fragment,
		...rest
	} = props;

	const {
		initialValues,
		formProps,
		form,
		submitting,
		pristine,
		values
	} = rest;

	const disabled = submitting || (pristine && equals(initialValues, {}));

	const finalFormProps = {
		...rest,
		formRef,
		disabled
	};

	formPropsRef.current = finalFormProps;

	function handleSubmit(event) {
		event.preventDefault();
		form.submit();
	}

	return (
		<form
			ref={formRef}
			onSubmit={handleSubmit}
			className={classes.form}
			noValidate
			// autoComplete="off"
			{...formProps}
		>
			<FormWrapper>
				<Grid
					container
					className={classes.container}
					columnSpacing={2}
				>
					{renderForm
						? renderForm(finalFormProps)
						: FormComponent
							? <FormComponent {...finalFormProps}/>
							: null
					}
				</Grid>
			</FormWrapper>
			{children}
		</form>
	);
});

const FinalForm = React.forwardRef(function FinalForm(props, ref) {
	const {
		classes: classesProp,
		className,
		actionType,
		initialValues: initialValuesProp = {},
		renderForm: renderFormProp,
		formData: formDataProp,
		config,
		onValidate,
		onSubmit,
		onReset,
		onSuccess,
		onSuccessClose,
		onError,
		onErrorClose,
		onResetState,
		componentId,
		formProps,
		extraValues,
		socketChannel,
		resetAfterSubmit = true,
		component: FormComponent,
		FinalFormComponent = DefaultFinalFormComponent,
		style,
		primusClient,
		fetchUrl,
		...rest
	} = props;

	const rootRef = React.useRef(null);
	const formRef = React.useRef(null);
	const handleFormRef = useForkRef(formRef, ref);
	const formPropsRef = React.useRef();
	const mountedRef = React.useRef();
	const classes = useClasses(props);
	const messages = config?.messages ? deepmerge(formConfig.messages, config.messages) : formConfig.messages;

	const formData = {
		submitSuccess: { ...messages.submitSuccess },
		submitError: { ...messages.submitError },
		...formDataProp
	};

	const [state, setState] = useAsyncSetState({
		success: false,
		error: false,
		submitting: false,
		dialogOpen: false,
		formMessage: ''
	});

	const {
		success,
		error,
		submitting,
		dialogOpen
	} = state;

	const [initialValues, setInitialValues] = useAsyncSetState(initialValuesProp);

	let timeout = null;

	async function setSuccess(formMessage = '') {
		await setState({
			...state,
			success: true,
			error: false,
			submitting: false,
			dialogOpen: true,
			formMessage
		});
	}

	async function setError(formMessage = '') {
		await setState({
			...state,
			success: false,
			error: true,
			submitting: false,
			dialogOpen: true,
			formMessage
		});
	}

	async function setSubmitting() {
		await setState({
			...state,
			success: false,
			error: false,
			submitting: true,
			dialogOpen: false
		});
	}

	function clearFormState() {
		return setComponentState(componentId, {});
	}

	function getFormState() {
		return getComponentState(componentId);
	}

	function setFormState(values) {
		if (mountedRef.current) {
			setComponentState(componentId, {
				...values
			});
		}
	}

	function handleValidate(values) {
		if (onValidate) {
			values = onValidate(values);
		}
		setFormState(values);
		return validate(values, config?.errors || formConfig.errors);
	}

	async function clearForm() {
		await setInitialValues(initialValuesProp || {});
		window.sessionStorage.removeItem(componentId);
	}

	async function handleOnSubmit(values, finalFormProps) {
		if (primusClient && !actionType) {
			throw new Error('ActionType missing!');
		}

		const { reset } = finalFormProps;

		const formProps = {
			...finalFormProps,
			clearFormState,
			getFormState,
			setFormState
		};

		const handleSuccess = (result) => {
			setSuccess(result?.formMessage);
			clearTimeout(timeout);
			if (resetAfterSubmit) {
				const {form} = formPropsRef.current;
				window.sessionStorage.removeItem(componentId);
				reset(initialValuesProp || {});
				form.restart();
			}
			if (onSuccess) onSuccess(result, formProps);
			window.dispatchEvent(new CustomEvent(`on${componentId}Success`, {
				result,
				formProps
			}));
		};

		const handleError = (err) => {
			setError(err?.code === 'FINAL_FORM_ERROR' ? err?.message : null);
			clearTimeout(timeout);
			if (onError) onError(err, formProps);
			window.dispatchEvent(new CustomEvent(`on${componentId}Error`, {
				error: err,
				formProps
			}));
		};

		setSubmitting();

		if (onSubmit) {
			await onSubmit(values);
		}

		timeout = setTimeout(() => {
			handleError(new Error('Socket Timeout'));
		}, 10000);

		if (primusClient) {
			await primusClient.action({
				type: actionType,
				data: {
					...values,
					...extraValues
				}
			}, socketChannel).then(handleSuccess).catch(handleError);
		} else {
			await fetchJson(fetchUrl || `/api/forms/${componentId}`, {
				data: {
					...values,
					...extraValues
				}
			}).then(handleSuccess).catch(handleError);
		}
	}

	async function handleResetState() {
		if (onResetState) await onResetState();
		await setState({
			...state,
			success: false,
			error: false,
			submitting: false,
			dialogOpen: false
		});
	}

	async function handleResetForm() {
		const {form} = formPropsRef.current;
		if (onReset) await onReset();
		await Promise.all([
			handleResetState(),
			clearForm()
		]);
		form.restart();
	}

	function handleSuccessClose() {
		handleResetState();
		if (onSuccessClose) {
			onSuccessClose();
		}
	}

	function handleErrorClose() {
		handleResetState();
		if (onErrorClose) {
			onErrorClose();
		}
	}

	useDidMount(() => {
		const componentState = getFormState();
		if (!equals(initialValues, componentState)) {
			setInitialValues({
				...initialValues,
				...componentState
			});
		}
		mountedRef.current = true;
	});

	React.useImperativeHandle(ref, () => ({
		node: rootRef.current,
		formNode: handleFormRef.current,
		form: formPropsRef.current?.form,
		resetForm: handleResetForm,
		clearForm,
		getFormState,
		setFormState,
		setInitialValues,
		setSuccess,
		setError,
		setSubmitting
	}));

	return (
		<div
			style={style}
			ref={rootRef}
			className={classes.root}
		>
			<Form
				rootRef={rootRef}
				formRef={handleFormRef}
				formPropsRef={formPropsRef}
				validate={handleValidate}
				onSubmit={handleOnSubmit}
				initialValues={initialValues}
				component={FinalFormComponent}
				componentId={componentId}
				resetForm={handleResetForm}
				clearForm={clearForm}
				formProps={formProps}
				formData={formData}
				renderForm={renderFormProp}
				FormComponent={FormComponent}
				getFormState={getFormState}
				setFormState={setFormState}
				setInitialValues={setInitialValues}
				setSuccess={setSuccess}
				setError={setError}
				setSubmitting={setSubmitting}
				socketChannel={socketChannel}
				classes={classes}
				{...rest}
			/>

			{formData.submitError && (
				<MessageSnackbar
					key="error"
					open={dialogOpen && error}
					clickaway
					onClose={handleErrorClose}
					variant="error"
					message={
						<>
							<strong>{formData.submitError.title}</strong><br/>
							{state.formMessage || formData.submitError.content}
						</>
					}
				/>
			)}

			{formData.submitSuccess && (
				<MessageSnackbar
					key="success"
					clickaway
					onClose={handleSuccessClose}
					open={dialogOpen && success}
					autoHideDuration={6000}
					variant={state.formMessage ? 'info' : 'success'}
					message={
						state.formMessage ? (
							<strong>{state.formMessage}</strong>
						) : (
							<>
								<strong>{formData.submitSuccess.title}</strong><br/>
								{formData.submitSuccess.content}
							</>
						)
					}
				/>
			)}

			<LoadingSnackbar
				key="loading"
				delay={500}
				open={submitting}
			>
				{messages.loading}
			</LoadingSnackbar>
		</div>
	);
});

FinalForm.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	actionType: PropTypes.string,
	loadingMessage: PropTypes.string,
	initialValues: PropTypes.object,
	formData: PropTypes.object,
	renderForm: PropTypes.func,
	onValidate: PropTypes.func,
	onSubmit: PropTypes.func,
	onReset: PropTypes.func,
	onResetState: PropTypes.func,
	onSuccess: PropTypes.func,
	onError: PropTypes.func,
	componentId: PropTypes.string.isRequired,
	formProps: PropTypes.object,
	extraValues: PropTypes.object,
	socketChannel: PropTypes.object,
	resetAfterSubmit: PropTypes.bool,
	component: PropTypes.elementType,
	style: PropTypes.object,
	primusClient: PropTypes.object,
	fetchUrl: PropTypes.string
};

export default React.memo(FinalForm);
