module.exports = {
	labels: {
		required: 'Pflichtfeld *',
		formOfAddress: 'Anrede',
		firstname: 'Vorname',
		lastname: 'Nachname',
		company: 'Firma',
		email: 'E-Mail',
		phone: 'Telefon',
		street: 'Straße und Hausnr.',
		zip: 'PLZ',
		city: 'Ort',
		concerns: 'Ihr Anligen richtet sich an',
		subject: 'Betreff',
		message: 'Ihre Nachricht oder Anfrage',
		comment: 'Bemerkung',
		submitTitle: 'Absenden',
		resetTitle: 'Zurücksetzen',
		signInTitle: 'Anmelden',
		signOutTitle: 'Abmelden',
		pickerCancelLabel: 'Abbrechen',
		pickerClearLabel: 'Löschen',
		pickerTodayLabel: 'Heute',
		invalidDateMessage: 'Ungültiges Datumsformat',
		minDateMessage: 'Datum darf nicht nach dem Höchstdatum liegen',
		maxDateMessage: 'Datum darf nicht vor dem Mindestdatum liegen'
	},
	options: {
		salutation: {
			mr: 'Sehr geehrter',
			mrs: 'Sehr geehrte'
		},
		formOfAddress: {
			mr: 'Herr',
			mrs: 'Frau'
		}
	},
	errors: {
		required: 'Bitte füllen Sie das Pflichtfeld aus!',
		email: 'Bitte geben Sie eine gültige E-Mail an!',
		zip: 'Bitte geben Sie eine gültige Postleitzahl an!',
		phone: 'Bitte geben Sie eine gültige Telefonnummer an!',
		link: 'Bitte geben Sie eine gültige URL an!'
	},
	messages: {
		submitSuccess: {
			title: 'Vielen Dank für Ihre Anfrage.',
			content: 'Wir werden uns in Kürze mit Ihnen in Verbindung setzen.'
		},
		submitError: {
			title: 'Leider ist ein Fehler aufgetreten!',
			content: 'Bitte versuchen Sie es später nochmal.\n\nVielen Dank für Ihr Verständnis.'
		},
		loading: 'Bitte warten, Daten werden gesendet.'
	},
	formData: {
		contactForm: {
			submitSuccess: {
				title: 'Vielen Dank für Ihre Kontakaufnahme.',
				content: 'Selbstverständlich werden wir Sie so schnell wie möglich kontaktieren!'
			}
		}
	}
};
