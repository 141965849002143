import * as React from 'react';
import ActionArea from 'react-app/components/ActionArea';

export default React.memo(React.forwardRef(function CardActionArea(props, ref) {
	return (
		<ActionArea
			component="div"
			cardActionMode
			{...((props.to || props.href) ? {
				noLinkStyle: true
			} : null)}
			{...props}
			ref={ref}
		/>
	);
}));
