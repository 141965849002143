import isEmail from 'validator/lib/isEmail';
import { isExternalLink } from '../../lib/helpers';
import formConfig from '../../../config/forms.cjs';

const { errors } = formConfig;

export {
	isEmail,
	isExternalLink
};

export const isRequired = value => (value || value === 0 ? undefined : errors.required);

export const isChecked = value => (value && value.length === 0 ? errors.required : undefined);

export const validate = (values, errors = formConfig.errors) => {
	const res = {};
	if (values.email !== undefined && !isEmail(values.email || '')) {
		res.email = errors.email;
	}
	if (values.zip && !values.zip.match(/^[0-9]{5}$/)) {
		res.zip = errors.zip;
	}
	if (values.phone && !values.phone.match(/^([0(+][0-9.-/ ()]{7,})$/)) {
		res.phone = errors.phone;
	}
	if (values.link && !values.link.startsWith('/') && !isExternalLink(values.link)) {
		res.link = errors.link;
	}
	return res;
};

export const mutators = {
	setValue: ([field, value], state, { changeValue }) => {
		changeValue(state, field, () => value);
	},
	setValues: ([items = {}], state, { changeValue }) => {
		Object.keys(items).forEach(key => {
			changeValue(state, key, () => items[key]);
		});
	}
};
