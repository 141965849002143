import { useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const Context = createContext();
const { Provider } = Context;

export function useAppData() {
	return useContext(Context);
}

export function AppDataProvider(props) {
	const { children, context = {} } = props;

	return (
		<Provider value={context}>
			{children}
		</Provider>
	);
}

AppDataProvider.propTypes = {
	children: PropTypes.node.isRequired,
	context: PropTypes.object.isRequired
};

export default Context;
