import types from './types.cjs';

export const initialState = {
	profile: {},
	isAuthenticated: false,
	errorMessage: '',
	loading: false,
	channels: {}
};

export default function authReducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.SESSION_REQUEST: {
			return {
				...state,
				loading: true
			};
		}

		case types.SESSION_CREATE: {
			return {
				...state,
				isAuthenticated: true,
				errorMessage: '',
				profile: action.profile,
				loading: false
			};
		}

		case types.SESSION_REFRESH: {
			return {
				...state,
				isAuthenticated: true,
				errorMessage: '',
				loading: false
			};
		}

		case types.SESSION_DESTROY: {
			return {
				...state,
				isAuthenticated: false,
				profile: {},
				loading: false
			};
		}

		case types.SESSION_ERROR: {
			return {
				...state,
				errorMessage: action.errorMessage || state.errorMessage,
				loading: false
			};
		}

		case types.SOCKET_CHANNEL_CREATE: {
			return {
				...state,
				channels: {
					...state.channels,
					[action.channelName]: action.channel
				}
			};
		}

		case types.SOCKET_CHANNEL_DESTROY: {
			if (state.channels[action.channelName]) {
				state.channels[action.channelName].end();
			}

			return {
				...state,
				channels: {
					...state.channels,
					[action.channelName]: null
				}
			};
		}
	}

	return state;
}

authReducer.initialState = initialState;
