import { memo } from 'react';
import { SvgIcon as  MuiSvgIcon } from '@mui/material';

function SvgIcon(path, displayName) {
	const Icon = memo(props => (
		<MuiSvgIcon {...props}>
			{path}
		</MuiSvgIcon>
	));
	Icon.displayName = displayName;
	Icon.muiName = 'SvgIcon';
	return Icon;
}

export default SvgIcon;
