import * as React from 'react';
import PropTypes from 'prop-types';
import { LocalStorage, Cookies, fetchJson } from 'lib/helpers';
import newsConfig from 'config/news.cjs';
import { useUser } from 'context/user';

const Context = React.createContext();
const { Provider } = Context;

export const getNotifyIds = () => {
	const {ids = []} = LocalStorage.get('notify') || {};
	return ids;
};

export const setNotifyId = (id) => {
	const ids = getNotifyIds();
	if (!ids.includes(id)) {
		ids.push(id);
		LocalStorage.set('notify', {ids});
	}
};

export const getNotifyData = async (config = {}) => {
	const result = await fetchJson('/api/news', {
		config,
		filter: {
			...newsConfig.filter,
			limit: null,
			find: {
				isNotification: true,
				$or: [
					{category: 'general'},
					...(config?.userType ? [{
						category: config?.userType
					}] : [])
				]
			}
		}
	});

	return result ? {
		data: result.data || [],
		count: result.count || 0,
	} : {};
};

export function useNotify() {
	return React.useContext(Context) || {};
}

function handleAppBannerSeen(id) {
	const COOKIE_NAME = 'appBanner';
	const values = (Cookies.get(COOKIE_NAME) || '').split(',').filter(Boolean);
	if (!id) return;
	if (!values.includes(id)) {
		values.push(id);
		Cookies.set(COOKIE_NAME, values.join(','));
	}
	return values;
}

function NotifyProvider(props) {
	const { context } = props;
	const [ {userType} ] = useUser();

	const [state, setState] = React.useState({
		items: [],
		count: 0,
		lastSeen: [],
		notSeenCount: 0,
		appBannersSeen: '',
		...context
	});

	const setLastSeen = () => {
		state.items.forEach(item => {
			setNotifyId(item.id);
		});
		setState({
			...state,
			lastSeen: getNotifyIds(),
			notSeenCount: 0
		});
	};

	const setSeenItems = () => {
		const notSeenItem = state.items.filter(item => !item.seen);
		if (notSeenItem.length === 0) {
			return;
		}
		const items = state.items.map(item => {
			item.seen = true;
			return item;
		});
		setState({
			...state,
			items
		});
	};

	const getItems = async (config) => {
		let notSeenCount = 0;

		const {data = []} = await getNotifyData({
			userType,
			...config
		});

		const items = data.map((item) => {
			const specifiedId = item.id || item.id === 0;
			const id = specifiedId ? item.id : new Date().getTime() + Math.random();
			const ids = getNotifyIds();

			if (specifiedId && ids.includes(id)) {
				item.seen = true;
			} else {
				if (!ids.length) {
					item.seen = true;
				}
				notSeenCount++;
			}
			return item;
		});

		setState({
			...state,
			items,
			count: items.length,
			lastSeen: getNotifyIds(),
			notSeenCount
		});
	};

	const setAppBannerSeen = (id) => {
		if (id) {
			const { appBannersSeen } = state;
			if (!appBannersSeen.includes(id)) {
				setState({
					...state,
					appBannersSeen: handleAppBannerSeen(id)
				});
			}
		} else {
			console.warn('ID missing!');
		}
	};

	const value = {
		...state,
		getItems,
		setSeenItems,
		setLastSeen,
		setAppBannerSeen
	};

	return (
		<Provider value={value}>
			{props.children}
		</Provider>
	);
}

NotifyProvider.propTypes = {
	context: PropTypes.object
};

export default React.memo(NotifyProvider);
