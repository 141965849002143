import * as React from 'react';
import LoadingSnackbar from '../LoadingSnackbar';

const LoadingSnackbarProps = {
	LoadingProps: {
		legacy: true
	},
	TransitionProps: {
		appear: false
	}
};

function AppLoading(props) {
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		setLoading(false);
	}, []);

	return (
		<LoadingSnackbar
			{...LoadingSnackbarProps}
			open={loading}
		>
			{props.children}
		</LoadingSnackbar>
	);
}

export default React.memo(AppLoading);
