import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { IconButton } from '@mui/material';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { Avatar } from '@mui/material';
import { Dialog as  MuiDialog } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { DialogActions } from '@mui/material';
import { useThemeProps } from '@mui/material/styles';
import CardActions from '../CardActions';
import { useBrowser } from '../../context/browser';
import CloseIcon from '@mui/icons-material/Close';

const useClasses = createClasses((theme, {fullScreen, fullWidth, scroll}) => ({
	root: {
		color: theme.vars.palette.text.primary
	},
	dialogTitle: {
		maxWidth: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: 'inherit',
		position: 'relative',
		minHeight: theme.spacing(7)
	},
	dialogTitleCloseIcon: {
		padding: theme.spacing(1, 2)
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: '1rem',
		color: 'inherit',
		...theme.mixins.gutters()
	},
	dialogActions: {},
	dialogActionsStart: {
		justifyContent: 'flex-start'
	},
	dialogActionsBetween: {
		justifyContent: 'space-between'
	},
	dialogActionsEnd: {
		justifyContent: 'flex-end'
	},
	title: {
		maxWidth: 'calc(100% - 48px)',
		display: 'flex',
		flexDirection: 'column'
	},
	heading: {
		display: 'flex',
		alignItems: 'center',
		fontSize: theme.typography.pxToRem(16),
		lineHeight: 1.2,
		[theme.breakpoints.up('tablet')]: {
			lineHeight: 1.3,
			fontSize: theme.typography.pxToRem(18)
		}
	},
	subheading: {
		...theme.mixins.textOverflow,
		lineHeight: 1.2,
		fontSize: theme.typography.pxToRem(14),
		[theme.breakpoints.up('tablet')]: {
			lineHeight: 1.3,
			fontSize: theme.typography.pxToRem(16)
		}
	},
	clamp: {
		...theme.mixins.lineClamp
	},
	clampOneLine: {
		WebkitLineClamp: 1
	},
	avatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		marginRight: theme.spacing(2)
	},
	closeIcon: {
		marginLeft: 'auto',
		marginRight: theme.spacing(-1)
	},
	paper: {
		...(!fullScreen && {
			[theme.breakpoints.down('sm')]: {
				margin: theme.spacing(1.5),
				...(scroll === 'paper' ? {
					maxHeight: `calc(100% - ${theme.spacing(3)})`
				} : {
					'&&': {
						maxWidth: `calc(100% - ${theme.spacing(3)})`
					}
				})
			}
		})
	},
	paperFullWidth: {},
	paperFullScreen: {
		...(fullWidth && !fullScreen && {
			width: '100%',
			margin: theme.spacing(1),
			maxHeight: `calc(100% - ${theme.spacing(2)})`,
			[theme.breakpoints.up('sm')]: {
				margin: theme.spacing(2),
				maxHeight: `calc(100% - ${theme.spacing(4)})`
			}
		})
	}
}), {
	name: 'RaDialog'
});

function Dialog(inProps) {
	const browser = useBrowser();
	const { isWidthDown, isWidthUp } = browser;

	const {
		classes: classesProp,
		className,
		children,
		fullScreen: fullScreenProp,
		title,
		subtitle,
		open,
		scroll: scrollProp,
		onClose,
		actions,
		buttons,
		icons,
		alignActions = 'end',
		showCloseIcon = true,
		showDividers = true,
		showTitleDivider = true,
		showActionDivider = true,
		avatar,
		titleContent,
		DialogClasses: DialogClassesProp,
		DialogContentProps,
		DialogWrapper = React.Fragment,
		scrollConfig = { xs: 'paper' },
		ssr,
		dense,
		...rest
	} = useThemeProps({ props: inProps, name: 'RaDialog' });

	let scroll = scrollProp;

	const fullScreen = typeof fullScreenProp === 'boolean'
		? fullScreenProp
		: isWidthDown(fullScreenProp || 'sm', browser.breakpoint);

	const props = React.useMemo(() => ({
		...inProps,
		fullScreen
	}), [inProps, fullScreen]);

	const classes = useClasses(props);

	const DialogClasses = React.useMemo(() => ({
		...DialogClassesProp,
		paper: clsx(classes.paper, DialogClassesProp?.paper),
		...((fullScreen || scroll === 'paper') && {
			paperFullWidth: clsx(classes.paperFullWidth, DialogClassesProp?.paperFullWidth),
			paperFullScreen: clsx(classes.paperFullScreen, DialogClassesProp?.paperFullScreen)
		})
	}), [DialogClassesProp, fullScreen, scroll, classes]);

	if (!scroll) {
		for (let [key, val] of Object.entries(scrollConfig)) {
			if (val && isWidthUp(key, browser.breakpointExt, true)) {
				scroll = val;
			}
		}
	}

	return (
		<MuiDialog
			fullScreen={fullScreen}
			open={open}
			scroll={fullScreen ? 'paper' : scroll}
			{...rest}
			onClose={onClose}
			classes={DialogClasses}
			className={classes.root}
			{...(ssr && {
				disablePortal: true,
				BackdropProps: {
					...props.BackdropProps,
					appear: false
				},
				TransitionProps: {
					...props.TransitionProps,
					appear: false
				}
			})}
		>
			{(title || showCloseIcon) && (
				<>
					<DialogTitle
						className={clsx(
							classes.dialogTitle,
							showCloseIcon && classes.dialogTitleCloseIcon
						)}
					>
						{avatar &&
							<Avatar className={classes.avatar} src={avatar}/>
						}
						<div className={classes.title}>
							{title && (
								<Typography
									color="inherit"
									variant="h6"
									component="div"
									className={clsx(
										classes.heading,
										classes.clamp,
										subtitle && classes.clampOneLine
									)}
								>
									{title}
								</Typography>
							)}
							{subtitle && (
								<Typography
									color="inherit"
									variant="subtitle2"
									component="div"
									className={classes.subheading}
								>
									{subtitle}
								</Typography>
							)}
						</div>
						{showCloseIcon &&
							<IconButton
								className={classes.closeIcon}
								color="inherit"
								onClick={onClose}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
						}
						{titleContent}
					</DialogTitle>

					{showDividers && showTitleDivider &&
						<Divider/>
					}
				</>
			)}

			<DialogWrapper>
				{children && (
					<DialogContent {...DialogContentProps} className={classes.dialogContent}>
						{children}
					</DialogContent>
				)}

				{actions ? (
					<>
						{showDividers && showActionDivider &&
							<Divider/>
						}
						<DialogActions
							className={clsx(
								classes.dialogActions,
								alignActions === 'start' && classes.dialogActionsStart,
								alignActions === 'between' && classes.dialogActionsBetween,
								alignActions === 'end' && classes.dialogActionsEnd
							)}
						>
							{actions}
						</DialogActions>
					</>
				) : (buttons || icons) ? (
					<CardActions
						dense={dense}
						divider
						className={classes.dialogActions}
						buttons={buttons}
						icons={icons}
					/>
				) : null}
			</DialogWrapper>
		</MuiDialog>
	);
}

Dialog.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	DialogClasses: PropTypes.object,
	DialogContentProps: PropTypes.object,
	actions: PropTypes.node,
	buttons: PropTypes.node,
	icons: PropTypes.node,
	title: PropTypes.node,
	subtitle: PropTypes.node,
	open: PropTypes.bool.isRequired,
	fullScreen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	onClose: PropTypes.func.isRequired,
	titleContent: PropTypes.node,
	alignActions: PropTypes.oneOf(['start', 'between', 'end']),
	showDividers: PropTypes.bool,
	showTitleDivider: PropTypes.bool,
	showActionDivider: PropTypes.bool,
	ssr: PropTypes.bool,
	dense: PropTypes.bool
};

export default React.memo(Dialog);
