import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from 'styles';
import Typography from '@mui/material/Typography';

const useClasses = createClasses((theme, {title}) => ({
	root: {
		lineHeight: {
			xs: 1.2,
			md: 1.3,
			lg: 1.4
		},
		fontSize: {
			xs: theme.typography.pxToRem(title ? 16 : 14),
			md: theme.typography.pxToRem(title ? 17 : 15),
			lg: theme.typography.pxToRem(title ? 18 : 16)
		}
	},
	subheading: {
		lineHeight: {
			xs: 1.1,
			md: 1.2,
			lg: 1.3
		},
		fontSize: {
			xs: theme.typography.pxToRem(14),
			md: theme.typography.pxToRem(15),
			lg: theme.typography.pxToRem(16)
		}
	}
}), {
	name: 'Headline'
});

function Headline(props) {
	const {
		children,
		title,
		subtitle,
		fontSize,
		SubheadingProps,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Typography
			variant="h6"
			component={title ? 'h3' : 'h4'}
			fontWeight={title ? 'medium' : 'regular'}
			color={title ? 'text.primary' : 'text.secondary'}
			{...rest}
			fontSize="inherit"
			className={clsx(
				classes.root,
				subtitle && !title && classes.subheading
			)}
		>
			{title || subtitle}
			{title && subtitle && (
				<Typography
					variant="body1"
					component="small"
					display="block"
					fontWeight="regular"
					color="text.secondary"
					{...SubheadingProps}
					fontSize="inherit"
					className={classes.subheading}
				>
					{subtitle}
				</Typography>
			)}
			{children}
		</Typography>
	);
}

Headline.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	SubheadingProps: PropTypes.object
};

export default React.memo(Headline);
