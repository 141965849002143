import createContext from '../create-context';
import * as actions from './actions';
import reducer from './reducer';

const {
	Context,
	ContextProvider,
	useContext
} = createContext(actions, reducer);

export {
	useContext as useApp,
	ContextProvider as AppProvider,
	reducer as appReducer,
	actions as appActions
};

export default Context;
