import * as React from 'react';
import PropTypes from 'prop-types';
import mediaQuery from 'css-mediaquery';
import { useDidMount, useMemoCallback } from '../../hooks';
import { BROWSER, debounce, isMobileDevice } from '../../lib/helpers';
import defaultBreakpoints from './breakpoints';
import equals from 'equals';

const Context = React.createContext();
const { Provider } = Context;

export function useBrowser() {
	return React.useContext(Context);
}

export function useMediaQuery(query = '', options) {
	const browser = useBrowser();
	return mediaQuery.match(query.replace(/^@media( ?)/m, ''), {
		width: `${browser.width}px`,
		...options
	});
}

export function useWindowResize(callback) {
	const browser = useBrowser();
	React.useEffect(() => {
		if (typeof callback === 'function') {
			return callback(browser);
		}
	}, [browser, callback]);
}

export const getBreakpoint = (breakpoints) => {
	let breakpoint = 'xs';

	if (!BROWSER) {
		return breakpoint;
	}

	for (let [key, value] of Object.entries(breakpoints?.values || {})) {
		if (window.innerWidth >= value) {
			breakpoint = key;
		}
	}

	return breakpoint;
};

export const setBrowserCookie = (breakpoints) => {
	const breakpoint = getBreakpoint(breakpoints);

	document.cookie = 'CH=j:' +
	JSON.stringify({
		w: window.innerWidth,
		h: window.innerHeight,
		cw: window.document.body.clientWidth,
		ch: window.document.body.clientHeight,
		dw: screen.width,
		dh: screen.height,
		res: +Math.max(screen.width, screen.height),
		dpr: (+window.devicePixelRatio || ',1'),
		brp: breakpoint,
		lazy: 'loading' in HTMLImageElement.prototype,
		t: ('ontouchstart' in window || 'msMaxTouchPoints' in navigator),
		m: isMobileDevice,
		share: 'share' in navigator,
		cs: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light'
	}) +
	';expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/';
};

export const lazyImageSupport = BROWSER && 'loading' in HTMLImageElement.prototype;

export function BrowserProvider(props) {
	const {
		children,
		breakpoints = defaultBreakpoints,
		context,
		lazyload
	} = props;

	const mountedRef = React.useRef();

	const isWidthUp = useMemoCallback((breakpoint, width, inclusive = true) => {
		const { keys = [] } = breakpoints;
		if (inclusive) {
			return keys.indexOf(breakpoint) <= keys.indexOf(width);
		}
		return keys.indexOf(breakpoint) < keys.indexOf(width);
	});

	const isWidthDown = useMemoCallback((breakpoint, width, inclusive) => {
		const { keys = [] } = breakpoints;
		if (inclusive) {
			return keys.indexOf(width) <= keys.indexOf(breakpoint);
		}
		return keys.indexOf(width) < keys.indexOf(breakpoint);
	});

	const getAppBarHeight = useMemoCallback(() => {
		let appBarHeight = 56;

		if (!BROWSER) {
			return appBarHeight;
		}

		if (isWidthUp('sm', getBreakpoint(breakpoints))) {
			appBarHeight = 64;
		} else if (window.matchMedia('(orientation: landscape)').matches) {
			appBarHeight = 48;
		}

		return appBarHeight;
	});

	const setAppBarHeight = useMemoCallback(() => {
		const appBarHeight = getAppBarHeight();
		document.documentElement.style.setProperty('--vw', `${window.innerWidth}px`);
		document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
		// document.documentElement.style.setProperty('--app-bar-height', `${appBarHeight}px`);
		return appBarHeight;
	});

	const [state, setState] = React.useState({
		breakpoint: getBreakpoint(breakpoints),
		appBarHeight: getAppBarHeight(),
		...context,
		lazyImageSupport
	});

	useDidMount(() => {
		const resizeListener = debounce(() => {
			setBrowserCookie(breakpoints);

			const breakpoint = getBreakpoint(breakpoints);
			const appBarHeight = setAppBarHeight();

			const nextState = {
				...state,
				breakpoint,
				width: window.innerWidth,
				height: window.innerHeight,
				clientWidth: window.document.body.clientWidth,
				clientHeight: window.document.body.clientHeight,
				deviceWidth: screen.width,
				deviceHeight: screen.height,
				devicePixelRatio: (+window.devicePixelRatio || ',1'),
				deviceResolution: +Math.max(screen.width, screen.height),
				isTouchDevice: ('ontouchstart' in window || 'msMaxTouchPoints' in navigator),
				isMobileDevice,
				shareSupport: 'share' in navigator,
				colorScheme: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light',
				appBarHeight
			};

			if (mountedRef.current) {
				setState(nextState);
			} else if (!equals(nextState, state)) {
				setState(nextState);
			}

			mountedRef.current = true;
		});

		resizeListener();

		window.addEventListener('resize', resizeListener);
		return () => window.removeEventListener('resize', resizeListener);
	});

	const value = {
		...state,
		lazyload,
		breakpoints,
		isWidthUp,
		isWidthDown,
		getAppBarHeight,
		setAppBarHeight
	};

	return (
		<Provider value={value}>
			{children}
		</Provider>
	);
}

BrowserProvider.propTypes = {
	children: PropTypes.object.isRequired,
	context: PropTypes.object.isRequired,
	breakpoints: PropTypes.shape({
		keys: PropTypes.array.isRequired,
		values: PropTypes.object.isRequired
	})
};

export default Context;
