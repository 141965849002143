import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';

const useClasses = createClasses((theme) => ({
	root: {
		...theme.mixins.flexContainer,
		...theme.mixins.spinnerFadeIn,
		minHeight: '100vh',
		maxWidth: '100%',
		overflow: 'hidden'
	}
}), {
	name: 'PageLoading'
});

function PageLoading(props) {
	const classes = useClasses(props);

	return (
		<div className={classes.root}/>
	);
}

PageLoading.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string
};

export default React.memo(PageLoading);
