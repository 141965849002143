/* global ActiveXObject */

import { isBrowser, hostUrl } from 'lib-js';
import equals from 'equals';
import pathToRegExp from 'path-to-regexp';

export { default as Cookies } from 'js-cookie';
export * from 'lib-js';
export const DEV = process.env.NODE_ENV !== 'production';
export const BROWSER = isBrowser;
export const HOST_URL = hostUrl;
export const CACHE_SUPPORT = typeof caches !== 'undefined';
export { pathToRegExp, equals };

export class SessionStorage extends Map {
	static set(id, value) {
		if (!BROWSER) return;
		if (typeof value === 'object') value = JSON.stringify(value);
		sessionStorage.setItem(id, value);
	}

	static get(id) {
		if (!BROWSER) return;
		const value = sessionStorage.getItem(id);
		try {
			return JSON.parse(value);
		} catch (e) {
			return value;
		}
	}

	static remove(id) {
		if (!BROWSER) return;
		return sessionStorage.removeItem(id);
	}

	static get del() {
		return SessionStorage.remove;
	}
}

export class LocalStorage extends Map {
	static set(id, value) {
		if (!BROWSER) return;
		if (typeof value === 'object') value = JSON.stringify(value);
		localStorage.setItem(id, value);
	}

	static get(id) {
		if (!BROWSER) return;
		const value = localStorage.getItem(id);
		try {
			return JSON.parse(value);
		} catch (e) {
			return value;
		}
	}

	static del(id) {
		if (!BROWSER) return;
		return localStorage.removeItem(id);
	}
}

export function getComponentState(id = '') {
	if (!BROWSER || !id) return {};
	return SessionStorage.get(id) || {};
}

export function setComponentState(id = '', state = {}) {
	if (!BROWSER || !id) return {};
	SessionStorage.set(id, state);
}

export function getRootRef(ref) {
	if (BROWSER && ref && ref.current && ref.current.rootRef && ref.current.rootRef.current) {
		return ref.current.rootRef.current;
	}
	return null;
}

export function areEqual(prevProps, nextProps) {
	return equals(prevProps, nextProps);
}

export function scrollToElementStart(element, offset = 0, {respectAppbar = true, smooth = false} = {}) {
	if (element) {
		const appBarHeight = respectAppbar ? parseInt(document.documentElement.style.getPropertyValue('--app-bar-height'), 10) : 0;
		const headerOffset = offset + appBarHeight;
		const elementPosition = element.getBoundingClientRect().top;
		const offsetPosition = window.pageYOffset + elementPosition - headerOffset;
		if (window.pageYOffset > offsetPosition) {
			if (smooth) {
				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth'
				});
			} else {
				window.scrollTo(0, offsetPosition);
			}
		}
	}
}

export function pdfSupport() {
	const hasAcrobatInstalled = () => {
		const getActiveXObject = (name) => {
			try { return new ActiveXObject(name); } catch (e) {}
		};
		return getActiveXObject('AcroPDF.PDF') || getActiveXObject('PDF.PdfCtrl');
	};
	const isIos = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	return navigator.mimeTypes['application/pdf'] || hasAcrobatInstalled() || isIos();
}

export async function fetchJson(url, data, config) {
	const result = await fetch(url, {
		method: 'POST',
		...config,
		headers: {
			'Content-Type': 'application/json',
			...config?.headers
		},
		body: JSON.stringify(data || {})
	});

	if (result?.ok) {
		return result.json();
	}

	throw new Error('Request failed!');
}

export function getGlobalScope() {
	if (BROWSER) {
		return window;
	}
	return global;
}
