import * as React from 'react';
import Button from '../Button';
import Link from '../Link';

export default React.memo(React.forwardRef(function LinkButton(props, ref) {
	const {
		noLinkStyle = true,
		LinkComponent = Link,
		...rest
	} = props;

	return (
		<Button
			{...((props.to || props.href) ? {
				component: LinkComponent,
				noLinkStyle
			} : null)}
			{...rest}
			ref={ref}
		/>
	);
}));
