import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from '../../styles';
import { Typography } from '@mui/material';
import AlertBox from '../AlertBox';

const useClasses = createClasses((theme) => ({
	root: {
		flexDirection: 'column'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		lineHeight: 1.4
	},
	title: {
		marginTop: theme.spacing(-.25),
		fontWeight: theme.typography.fontWeightMedium,
		whiteSpace: 'pre-line'
	},
	content: {
		fontWeight: theme.typography.fontWeightRegular,
		whiteSpace: 'pre-line'
	},
	action: {
		padding: 0,
		paddingBottom: theme.spacing(.5),
		justifyContent: 'center'
	},
	icon: {}
}), {
	name: 'RaMessageBox'
});

function MessageBox(props) {
	const {
		classes: classesProp,
		className,
		children,
		title,
		content,
		type = 'default',
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<AlertBox
			type={type}
			component="div"
			className={classes.root}
			classes={{
				action: classes.action,
				icon: classes.icon
			}}
			{...rest}
		>
			<div className={classes.container}>
				{title &&
					<Typography
						className={classes.title}
						color="inherit"
						variant="body1"
						component="div"
					>
						{title}
					</Typography>
				}

				{content &&
					<Typography
						className={classes.content}
						color="inherit"
						variant="body2"
						component="div"
					>
						{content}
					</Typography>
				}
				{children}
			</div>
		</AlertBox>
	);
}

MessageBox.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	title: PropTypes.node,
	content: PropTypes.node
};

export default React.memo(MessageBox);
