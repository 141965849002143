import * as React from 'react';
import clsx from 'clsx';
import createCache from '@emotion/cache';
import { insertStyles, getRegisteredStyles } from '@emotion/utils';
import { css, __unsafe_useEmotionCache as useEmotionCache, CacheProvider } from '@emotion/react';
import { serializeStyles } from '@emotion/serialize';
import { unstable_styleFunctionSx as styleFnSx } from '@mui/system';
import { deepmerge, isPlainObject, getCookie, DEV } from '../lib/helpers';
import { extendTheme } from './theme';
import useDetectColorScheme from '../hooks/useDetectColorScheme';
import { useApp } from '../context/app';
import {
	ThemeProvider as MuiThemeProvider,
	useColorScheme,
	useTheme
} from '@mui/material/styles';

const iterateObject = (obj = {}, cb) => {
	for (let [key, val] of Object.entries(obj)) {
		if (isPlainObject(val)) {
			iterateObject(val, cb, obj);
		}
		if (typeof cb === 'function') {
			cb(key, val, obj);
		}
	}
};

const createClassNames = (args = [], cache) => {
	const serialized = serializeStyles(args, cache.registered);
	insertStyles(cache, serialized, false);
	return `${cache.key}-${serialized.name}`;
};

const mergeClassNames = (className, cache) => {
	const registeredStyles = [];

	const rawClassName = getRegisteredStyles(
		cache.registered,
		registeredStyles,
		className
	);

	if (registeredStyles.length < 2) {
		return className;
	}

	return rawClassName + createClassNames(registeredStyles, cache);
};

export * from './theme';

export {
	CacheProvider,
	useEmotionCache,
	useTheme,
	useColorScheme,
	clsx,
	css,
	styleFnSx,
	deepmerge
};

export { useThemeProps } from '../tailwind/styles';

export const cacheOptions = {
	key: 'css',
	...(DEV && { stylisPlugins: [] }),
	get nonce() {
		return global.__webpack_nonce__ || global.__style_nonce__;
	}
};

export function createEmotionCache({compat = true, ...options} = {}) {
	const cache = createCache({
		...cacheOptions,
		...options
	});
	cache.compat = compat;
	return cache;
}

export function makeStyles(fn, theme = {}, props = {}, name) {
	const styles = typeof fn === 'function' ? fn(theme, props) : fn || {};

	const { styleOverrides } = theme?.components?.[name] || {};

	const getStyleOverwrites = () => {
		if (styleOverrides) {
			return Object.fromEntries(Object.entries(styleOverrides).map(([key, val]) => ([
				key, typeof val === 'function' ? val({ ownerState: props, theme}) : val
			])));
		}
		return null;
	};

	return props?.styles || styleOverrides ? deepmerge.all([
		styles,
		getStyleOverwrites(),
		props?.styles
	]) : styles;
}

export function createStyles(fn, {name} = {}) {
	return (props) => {
		const theme = useTheme();
		return React.useMemo(() => {
			const styles = makeStyles(fn, theme, props, name);

			if (props?.sx) {
				styles.root = cx(styles.root, props.sx);
			}

			return DEV ? Object.fromEntries(
				Object.keys(styles).map(key => [key, {
					label: [name, key].filter(Boolean).join('-'),
					...styles[key]
				}])
			) : styles;
		}, [theme, props]);
	};
}

export function createClasses(fn, {name, mergeClassName = true} = {}) {
	return (props) => {
		const theme = useTheme();
		const { cxCls, sxCls } = useClsUtils();

		return React.useMemo(() => {
			const styles = makeStyles(fn, theme, props, name);
			return Object.fromEntries(
				Object.keys(styles).map(key => [key,
					cxCls(sxCls(DEV ? {
						label: [name, key].filter(Boolean).join('-'),
						...styles[key]
					} : styles[key]), props?.classes?.[key], mergeClassName && key === 'root' && props?.className)
				])
			);
		}, [theme, props, cxCls, sxCls]);
	};
}

export function useClasses(styles = {}, {className, classes, mergeClassName = true} = {}) {
	const { cxCls, sxCls } = useClsUtils();

	return React.useMemo(() => (
		Object.fromEntries(
			Object.keys(styles).map(key => {
				const value = [
					key,
					cxCls(sxCls(styles[key]), classes?.[key], mergeClassName && key === 'root' && className)
				];
				return value;
			})
		)
	), [styles, className, classes, mergeClassName, cxCls, sxCls]);
}

export function cx(...args) {
	return deepmerge.all([...args.filter(Boolean)]);
}

export function useSx() {
	const theme = useTheme();
	return (styles = {}) => styleFnSx({
		theme,
		sx: styles
	});
}

export function useClsUtils() {
	const sx = useSx();
	const cache = useEmotionCache();
	if (!cache) {
		throw new TypeError('Emotion cache missing!');
	}

	return React.useMemo(() => {
		const cssCls = (...args) => createClassNames(args, cache);
		const cxCls = (...args) => mergeClassNames(clsx(args), cache);
		const sxCls = (...args) => createClassNames(args.map(sx), cache);
		const cxsx = (...args) => cxCls(...args.map(arg => typeof arg === 'string' ? arg : sxCls(arg)));

		return {
			cssCls,
			cxCls,
			sxCls,
			cxsx
		};
	}, [cache, sx]);
}

export function sxMerge(...styles) {
	const capacitor = [];
	for (const sx of styles) {
		if (sx) {
			if (Array.isArray(sx)) {
				for (const sub of sx) {
					capacitor.push(sub);
				}
			} else {
				capacitor.push(sx);
			}
		}
	}
	return capacitor;
}

function ColoModeSwitch({children}) {
	const [{ colorMode }, { setColorMode }] = useApp();
	const { mode, setMode } = useColorScheme();

	React.useEffect(() => {
		if (mode !== colorMode) {
			setMode(colorMode);
			setColorMode(colorMode);
		}
	}, [mode, setMode, colorMode, setColorMode]);

	return children;
}

export function ThemeProvider(props) {
	const {
		children,
		createTheme = extendTheme,
		themeConfig,
		config,
		...rest
	} = props;

	const [{ colorMode }] = useApp();

	const theme = createTheme(themeConfig, {
		...config,
		mode: colorMode
	});

	return (
		<MuiThemeProvider defaultMode={colorMode} {...rest} theme={theme}>
			<ColoModeSwitch>
				{children}
			</ColoModeSwitch>
		</MuiThemeProvider>
	);
}

export function useDetectColorMode() {
	const [{ colorMode }, { setColorMode }] = useApp();
	const preferdColorMode = useDetectColorScheme(colorMode);

	React.useEffect(() => {
		if (!getCookie('colorMode') && preferdColorMode !== colorMode) {
			setColorMode(preferdColorMode, false);
		}
	}, [colorMode, setColorMode, preferdColorMode]);

	return colorMode;
}

export function useColorModeValue(...args) {
	const theme = useTheme();
	return theme.helpers.colorModeValue(...args);
}

export function useColorSchemeSelector(colorMode) {
	const theme = useTheme();
	const { colorSchemeSelector } = theme;
	return {...{[colorSchemeSelector]: colorMode || theme.config.colorMode}};
}
