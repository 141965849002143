import * as React from 'react';
import Notification from '../Notification';

function MessageSnackbar(props) {
	return (
		<Notification
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			{...props}
		/>
	);
}

MessageSnackbar.displayName = 'MessageSnackbar';

export default React.memo(MessageSnackbar);
