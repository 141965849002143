import { useEffect, useRef, useCallback, useReducer } from 'react';

export default function useAsyncReducer(reducer, initialState = {}) {
	const [state, dispatch] = useReducer(reducer, {
		...reducer.initialState,
		...initialState
	});

	const dispatchRef = useRef();

	const asyncDispatch = useCallback((action) => {
		if (typeof action === 'function') {
			return action(asyncDispatch, {...state});
		}

		if (action && typeof action.then === 'function') {
			return action.then(action => asyncDispatch(action));
		}

		return new Promise(resolve => {
			dispatchRef.current = resolve;
			dispatch(action);
		});
	}, [state]);

	useEffect(() => {
		if (dispatchRef.current) {
			dispatchRef.current(state);
			dispatchRef.current = null;
		}
	}, [state]);

	return [{...state}, asyncDispatch];
}
