import { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useRouteLoaderData, useNavigation } from 'react-router-dom';
import equals from 'equals';
import { BROWSER } from '../../lib/helpers';
import { useAppData } from '../app-data';
import { findPage, matchPage } from './helpers';
import { usePrevious, useMemoCallback, useAsyncError } from '../../hooks';
import types from './types.cjs';

const Context = createContext();
const { Provider } = Context;

export async function fetchPageData({pageId, pathname, config, fetchUrl = '/api/page-data', primusClient} = {}) {
	if (!BROWSER) {
		return global.__ROOT_CONTEXT__?.pageDataContext || null;
	}

	if (!pageId) {
		throw new Error('PageId missing!');
	}

	const data = {
		type: types.GET_PAGE_DATA,
		data: {
			pageId,
			config,
			resolvedUrl: pathname
		}
	};

	const result = primusClient
		? await primusClient.action(data)
		: await fetch(fetchUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8'
			},
			body: JSON.stringify(data)
		}).then(res => res.json());

	return result;
}

export function getPage(pathname, pages) {
	pages = pages || global.__APP_DATA__?.pages || {};
	const page = findPage(pages, pathname) || matchPage(pages, pathname) || null;
	if (page && !page?.options?.dropDownMenuOnly) {
		return page;
	}
	return null;
}

export function useGetPage() {
	const { pages } = useAppData();
	return (pathname) => getPage(pathname, pages);
}

export function createDataRoute(props = {}) {
	const {
		fetchUrl,
		fetchConfig: config,
		primusClient,
		...rest
	} = props;

	return {
		...rest,
		loader: async ({request}) => {
			const { id: pageId } = props;
			const { pathname } = new URL(request.url);
			return await fetchPageData({pageId, pathname, config, fetchUrl, primusClient});
		}
	};
}

export function usePageProps(props) {
	const {
		pageKeySuffix,
		context
	} = props;

	const location = useLocation();
	const pathname = decodeURI(location.pathname);
	const { pages } = useAppData();

	const pageKey = pageKeySuffix ? `${pathname}_${pageKeySuffix}` : pathname;
	const prevPageKey = usePrevious(pageKey);

	const matched = matchPage(pages, pathname);
	const page = !prevPageKey && context?.id
		? context
		: findPage(pages, pathname) || matched || pages?.notFound || {};

	const pageId = page.id;
	const prevPageId = usePrevious(pageId);

	const isActive = pathname === page.asPath
		|| pathname === page.pathname
		|| matched?.pathname === page.pathname
		|| page.aliases?.includes?.(page.asPath || page.pathname);

	return {
		page,
		pageKey,
		prevPageKey,
		pageId,
		prevPageId,
		isActive,
		pathname
	};
}

export function usePageData() {
	return useContext(Context);
}

export function PageDataProvider(props) {
	const {
		children,
		fetchUrl,
		primusClient,
		onPageChangeStart,
		onPageChangeEnd,
		onPageChangeError
	} = props;

	const { page, pathname: href, ...restPageProps } = usePageProps(props);
	const data = useRouteLoaderData(page.id);

	const pageProps = useMemo(() => ({
		...restPageProps,
		...page,
		...data
	}), [restPageProps, page, data]);

	const { pathname, pageId, pageKey, prevPageKey } = pageProps;

	const [ pagesProps, setPagesProps ] = useState({
		[pageKey]: {...pageProps}
	});

	const isLoadingRef = useRef();
	const throwError = useAsyncError();
	const navigation = useNavigation();
	const isLoading = navigation.state === 'loading';

	const handlePageChangeStart = useMemoCallback(() => {
		if (typeof onPageChangeStart === 'function') {
			onPageChangeStart(pageProps);
		}
	});

	const handlePageChangeEnd = useMemoCallback(() => {
		if (typeof onPageChangeEnd === 'function') {
			onPageChangeEnd(pageProps);
		}
	});

	const handlePageChangeError = useMemoCallback((err) => {
		if (typeof onPageChangeError === 'function') {
			onPageChangeError(err, pageProps);
		}
		throwError(err);
	});

	const getPageData = useMemoCallback(async (config, noCache) => (
		(!noCache && pagesProps[pageKey]) || await fetchPageData({pageId, pathname, config, fetchUrl, primusClient}).catch(handlePageChangeError)
	));

	const setPageData = useMemoCallback(async (config, noCache = true) => {
		isLoadingRef.current = true;
		handlePageChangeStart();
		const nextPageProps = await getPageData(config, noCache);
		isLoadingRef.current = false;
		if (!equals(pageProps, nextPageProps)) {
			setPagesProps({
				...pagesProps,
				[pageKey]: {...nextPageProps}
			});
			return nextPageProps;
		}
		return pageProps;
	});

	useEffect(() => {
		if (prevPageKey) {
			if (isLoading) {
				handlePageChangeStart();
			} else if (!isLoadingRef.current) {
				handlePageChangeEnd();
			}
		}
	}, [isLoading, prevPageKey, handlePageChangeStart, handlePageChangeEnd]);

	const value = [{
		...(pagesProps[pageKey] || pageProps),
		href
	}, {
		getPageData,
		setPageData
	}];

	return (
		<Provider value={value}>
			{children}
		</Provider>
	);
}

PageDataProvider.propTypes = {
	children: PropTypes.node.isRequired,
	context: PropTypes.object,
	onPageChangeStart: PropTypes.func,
	onPageChangeEnd: PropTypes.func,
	onPageChangeError: PropTypes.func,
	fetchUrl: PropTypes.string,
	pageKeySuffix: PropTypes.string,
	primusClient: PropTypes.shape({
		action: PropTypes.func
	})
};

export default Context;
