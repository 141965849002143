import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { Dialog } from '@mui/material';
import { IconButton } from '@mui/material';
import { Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '../FullscreenDialogAppBar';
import Fab from '../Fab';
import Delay from '../Delay';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useClasses = createClasses((theme, {noAppBar}) => ({
	root: {},
	container: {
		display: 'flex',
		backgroundColor: theme.config.darkMode ? theme.vars.palette.background.level4 : theme.vars.palette.grey[800],
		width: '100%',
		height: '100%',
		position: 'relative',
		overflow: 'hidden'
	},
	content: {
		display: 'flex',
		width: '100%',
		height: '100%',
		overflow: 'auto'
	},
	appBar: {
		paddingRight: '0 !important'
	},
	centered: {
		display: 'flex',
		flexDirection: 'column',
		height: 'auto',
		minHeight: 'calc(100% - 56px)',
		[`${theme.breakpoints.between('xs', 'tablet')} and (orientation: landscape)`]: {
			minHeight: 'calc(100% - 48px)'
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: 'calc(100% - 64px)'
		},
		'& > *': {
			margin: 'auto !important'
		},
		...(noAppBar && {
			minHeight: '100%'
		})
	},
	fab: {
		position: 'absolute',
		zIndex: 1000,
		top: theme.spacing(1),
		right: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			top: theme.spacing(2),
			right: theme.spacing(2)
		},
		[theme.breakpoints.up('md')]: {
			top: theme.spacing(3),
			right: theme.spacing(3)
		}
	},
	closeIcon: {
		padding: theme.spacing(1),
		marginRight: theme.spacing(-1.5),
		[theme.breakpoints.down('phone')]: {
			marginRight: theme.spacing(-1)
		}
	}
}), {
	name: 'RaFullscreenDialog'
});

function FullscreenDialogContent(props) {
	const {
		classes,
		children,
		onClose,
		title,
		centered = false,
		AppBarComponent = AppBar,
		AppBarProps,
		noAppBar,
		delay = 255
	} = props;

	function handleClose(event) {
		onClose(event);
		event.preventDefault();
	}

	return (
		<>
			{!noAppBar ? (
				<AppBarComponent
					onClick={handleClose}
					title={title}
					{...AppBarProps}
					className={clsx(
						classes.appBar,
						AppBarProps?.className
					)}
				>
					<IconButton color="inherit" onClick={handleClose} className={classes.closeIcon}>
						<CloseIcon />
					</IconButton>
				</AppBarComponent>
			) : (
				<Fab
					flat
					onClick={handleClose}
					className={classes.fab}
					Icon={CloseIcon}
				/>
			)}

			<div className={classes.container}>
				<div
					className={clsx(
						classes.content,
						centered && classes.centered
					)}
				>
					{children ? (
						<Delay delay={delay}>
							{children}
						</Delay>
					) : null}
				</div>
			</div>
		</>
	);
}

function FullscreenDialog(props) {
	const {
		classes: classesProp,
		className,
		children,
		title,
		open,
		centered,
		onClose,
		AppBarComponent,
		AppBarProps,
		noAppBar,
		delay,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Dialog
			fullScreen
			TransitionComponent={Transition}
			onClose={onClose}
			open={open}
			{...rest}
			className={classes.root}
		>
			<FullscreenDialogContent
				classes={classes}
				onClose={onClose}
				title={title}
				centered={centered}
				AppBarComponent={AppBarComponent}
				AppBarProps={AppBarProps}
				noAppBar={noAppBar}
				delay={delay}
			>
				{children}
			</FullscreenDialogContent>
		</Dialog>
	);
}

FullscreenDialog.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	title: PropTypes.string,
	open: PropTypes.bool.isRequired,
	centered: PropTypes.bool,
	noAppBar: PropTypes.bool,
	initialEntered: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	AppBarComponent: PropTypes.elementType,
	AppBarProps: PropTypes.object
};

export default React.memo(FullscreenDialog);
