import Button from 'components/Button';
import Error from 'react-app/components/Error';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppContent from 'components/AppContent';
import { useApp } from 'react-app/context/app';
import { appData } from 'config/data.cjs';

const { messages } = appData;

export default function Error404Route() {
	const {
		pages: {
			notFound: {
				title,
				subtitle,
				buttonTitle = messages.button.backHome
			}
		}
	} = appData;

	const [ {routerContext} ] = useApp();
	routerContext.status = 404;

	return (
		<AppContent>
			<Error
				statusCode={404}
				title={title}
				description={subtitle}
			>
				<Typography component="div">
					<Box textAlign="center">
						<Button color="primary" href="/">
							{buttonTitle}
						</Button>
					</Box>
				</Typography>
			</Error>
		</AppContent>
	);
}
