import * as React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@mui/material';
import { FormHelperText } from '@mui/material';

const FinalFormControl = (props) => {
	let {
		children,
		meta,
		FormHelperTextProps,
		...rest
	} = props;

	const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

	return (
		<FormControl
			{...rest}
			error={showError}
		>
			{children}
			{showError &&
				<FormHelperText {...FormHelperTextProps}>
					{meta.error || meta.submitError}
				</FormHelperText>
			}
		</FormControl>
	);
};

FinalFormControl.propTypes = {
	meta: PropTypes.object.isRequired,
	FormHelperTextProps: PropTypes.object
};

export default FinalFormControl;
