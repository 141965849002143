import * as React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';

const FinalFormCheckbox = (props) => {
	let {
		input: {
			name,
			onChange,
			checked,
			...restInput
		},
		size,
		label,
		InputProps,
		inputProps: {
			onChange: onChangeProp,
			...inputProps
		} = {},
		checked: checkedProp,
		...rest
	} = props;

	function handleChange(...args) {
		onChange(...args);
		if (onChangeProp) {
			onChangeProp(...args);
		}
	}

	return (
		<FormControlLabel
			{...rest}
			label={label}
			control={
				<Checkbox
					{...InputProps}
					size={size}
					inputProps={{
						...inputProps,
						...restInput,
						type: 'checkbox'
					}}
					name={name}
					onChange={handleChange}
					checked={checked}
				/>
			}
		/>
	);
};

FinalFormCheckbox.propTypes = {
	input: PropTypes.object.isRequired
};

export default FinalFormCheckbox;
