import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx, useTheme } from 'styles';
import Img from 'react-app/components/Img';
import Button from 'components/Button';
import Link from 'components/Link';
import BrandLogo from './BrandLogo';
import { logo_hw, logo_hw_mono, logo_hw_retail, logo_hw_retail_mono } from 'config/images';
import { useDeferredUser } from 'context/user';

const useClasses = createClasses((theme, { width = 134 }) => ({
	root: {
		color: theme.vars.palette.text.primary,
		textDecoration: 'none',
		position: 'relative',
		boxSizing: 'content-box',
		borderRadius: 48,
		marginLeft: theme.spacing(-1.5),
		padding: theme.spacing(1.25, 1.5),
		width,
		[theme.breakpoints.down('phone')]: {
			marginLeft: theme.spacing(-.5),
			padding: theme.spacing(1, 1.25),
			width: Math.round(width * .9)
		},
		[theme.config.mediaQueries.touch]: {
			'&&:hover:before': {
				opacity: 0
			}
		}
	},
	icon: {
		width: '100%',
		height: 'auto'
	}
}), {
	name: 'Brand'
});

function Brand(props) {
	const {
		classes: classesProp,
		className,
		variant = 'image',
		BrandLogoProps,
		BrandLogoComponent = BrandLogo,
		src,
		...rest
	} = props;

	const classes = useClasses(props);
	const theme = useTheme();

	const { darkMode } = theme.config;
	const [ { isRetail } ] = useDeferredUser();

	return (
		variant === "button" ? (
			<Button
				component={Link}
				color="inherit"
				href="/"
				aria-label="Holz Weidauer"
				{...rest}
				className={clsx(
					classes.root,
					className
				)}
			>
				<BrandLogoComponent {...BrandLogoProps} darkMode={darkMode} className={classes.icon}/>
			</Button>
		) : (
			<Img
				width={180}
				height={79}
				alt="Holz Weidauer"
				{...rest}
				className={className}
				src={src || (isRetail
					? darkMode ? logo_hw_retail_mono?.src : logo_hw_retail?.src
					: darkMode ? logo_hw_mono?.src : logo_hw?.src
				)}
			/>
		)
	);
}

Brand.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	variant: PropTypes.oneOf(['image', 'button'])
};

export default React.memo(Brand);
