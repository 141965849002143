import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { Fab as  MuiFab } from '@mui/material';
import BaseButton from '../Button';

const Button = React.memo(React.forwardRef(function Button(props, ref) {
	const {isFab, ...rest} = props;

	return (
		isFab ? (
			<MuiFab {...rest} ref={ref}/>
		) : (
			<BaseButton {...rest} ref={ref}/>
		)
	);
}));

Button.propTypes = {
	isFab: PropTypes.bool.isRequired,
	children: PropTypes.node,
	variant: PropTypes.string
};

const defaultProps = {
	variant: 'circular',
	size: 'medium',
	flat: false
};

const useClasses = createClasses((theme, {size = defaultProps.size, isFab}) => {
	const smallFab = isFab && size === 'small';
	const mediumFab = isFab && size === 'medium';
	const largeFab = isFab && size === 'large';
	const hugeFab = isFab && size === 'huge';

	return {
		root: {},
		extendedFab: {
			width: 'auto',
			...(smallFab && {
				fontSize: theme.typography.pxToRem(13),
				paddingLeft: theme.spacing(1.5),
				paddingRight: theme.spacing(1.5)
			}),
			...(mediumFab && {
				fontSize: theme.typography.pxToRem(14),
				paddingLeft: theme.spacing(2.5),
				paddingRight: theme.spacing(2.5)
			}),
			...(largeFab && {
				fontSize: theme.typography.pxToRem(15),
				paddingLeft: theme.spacing(3),
				paddingRight: theme.spacing(3),
				height:  theme.spacing(6),
				borderRadius: theme.spacing(3)
			}),
			...(hugeFab && {
				fontSize: theme.typography.pxToRem(20),
				paddingLeft: theme.spacing(3.5),
				paddingRight: theme.spacing(3.5),
				height:  theme.spacing(7),
				borderRadius: theme.spacing(3.5)
			})
		},
		extendedFabWithIcon: {
			paddingLeft: 0
		},
		flatFab: {
			boxShadow: 'none',
			borderRadius: '50%',
			minWidth: 'auto',
			minHeight: 'auto',
			background: 'rgba(0, 0, 0, .2)',
			color: '#fff',
			'&:active, &:hover, &:focus': {
				boxShadow: 'none',
				background: 'rgba(0, 0, 0, .4)'
			}
		},
		fabIcon: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			position: 'relative',
			borderRadius: '50%',
			overflow: 'hidden',
			'&:before': {
				top: 0,
				left: 0,
				display: 'inline-block',
				position: 'absolute',
				content: '""',
				width: '100%',
				height: '100%',
				background: 'currentColor',
				opacity: .2
			},
			...(smallFab && {
				marginRight: theme.spacing(1),
				width: theme.spacing(4),
				height: theme.spacing(4)
			}),
			...(mediumFab && {
				marginRight: theme.spacing(1.5),
				width: theme.spacing(5),
				height: theme.spacing(5)
			}),
			...(largeFab && {
				marginRight: theme.spacing(2),
				width: theme.spacing(6),
				height: theme.spacing(6)
			}),
			...(hugeFab && {
				marginRight: theme.spacing(2.5),
				width: theme.spacing(7),
				height: theme.spacing(7),
				'& > svg': {
					fontSize: '2rem'
				}
			})
		},
		smallFab: {},
		mediumFab: {},
		largeFab: {},
		hugeFab: {}
	};
}, {
	name: 'RaFab',
	mergeClassName: false
});

const Fab = React.forwardRef(function Fab(inProps, ref) {
	const {
		classes: classesProp,
		className,
		children,
		variant = defaultProps.variant,
		size = defaultProps.size,
		flat = defaultProps.flat,
		Icon,
		IconProps,
		...rest
	} = inProps;

	const isFab = flat || variant === 'round' || variant === 'extended';

	const props = React.useMemo(() => ({
		...inProps,
		isFab
	}), [inProps, isFab]);

	const classes = useClasses(props);

	return (
		<Button
			size={size === 'huge' && isFab ? 'large' : size}
			variant={isFab && children ? 'extended' : variant || undefined}
			isFab={isFab}
			{...rest}
			ref={ref}
			className={clsx(
				classes.root,
				isFab && size === 'small' && classes.smallFab,
				isFab && size === 'medium' && classes.mediumFab,
				isFab && size === 'large' && classes.largeFab,
				isFab && size === 'huge' && classes.hugeFab,
				isFab && children && classes.extendedFab,
				isFab && children && Icon && classes.extendedFabWithIcon,
				isFab && flat && classes.flatFab,
				className
			)}
		>
			{Icon &&
				<>
					{children ? (
						<div className={classes.fabIcon}>
							<Icon {...IconProps} />
						</div>
					) : (
						<Icon {...IconProps} />
					)}
				</>
			}
			{children}
		</Button>
	);
});

Fab.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	variant: PropTypes.string,
	size: PropTypes.string,
	flat: PropTypes.bool,
	Icon: PropTypes.elementType,
	IconProps: PropTypes.object
};

export default React.memo(Fab);

