/* global WEBPACK_DEV */

import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import AppLoading from 'react-app/components/AppLoading';
import NProgress, { nprogress } from 'react-app/components/NProgress';
import NoScriptHint from 'react-app/components/NoScriptHint';
import DialogProvider from 'react-app/components/DialogProvider';
import { ScrollRestoration } from 'react-router-dom';
import { ThemeProvider, createTheme, useTheme } from 'styles';
import { PageDataProvider, usePageData } from 'react-app/context/page-data';
import { UserProvider, useUser, useDeferredUser } from 'context/user';
import { AuthProvider, useAuth } from 'context/auth';
import { usePrevious } from 'react-app/hooks';
import { NotifyProvider } from 'components/Notify';
import ErrorBoundary from 'components/ErrorBoundary';
import FullscreenCarouselDialog from 'components/FullscreenCarouselDialog';
import UserTypeDialog from 'components/UserTypeDialog';
import { appData, initialState } from 'config/data.cjs';

const { isCzech, isFactory, messages } = appData;

function handelePageChangeStart(page) {
	nprogress.start();
	window.dispatchEvent(new CustomEvent('pageChangeStart', { detail: {page} }));
}

function handelePageChangeEnd(page) {
	nprogress.done();
	window.dispatchEvent(new CustomEvent('pageChangeEnd', { detail: {page} }));
}

function handelePageChangeError(err, page) {
	nprogress.done();
	window.dispatchEvent(new CustomEvent('pageChangeError', { detail: {err, page} }));
}

function ThemeProviderWrapper(props) {
	const { children } = props;
	const [ {userType, isRetail, isWholesale} ] = useDeferredUser();

	const [ pageData ] = usePageData();
	const themeVariantId = pageData?.options?.themeVariantId;
	const config = {userType, isRetail, isWholesale, themeVariantId};

	return (
		<ThemeProvider createTheme={createTheme} config={config}>
			{children}
		</ThemeProvider>
	);
}

function Providers() {
	const { pages = {} } = appData || {};

	const [ auth ] = useAuth();
	const [{
		userDialogOpen,
		userType
	}] = useUser();

	const {
		pageDataContext = {},
		notifyContext = {}
	} = initialState;

	const { isAdmin = false } = auth?.profile || {};

	return (
		<PageDataProvider
			context={pageDataContext}
			pageKeySuffix={`${userType}${isAdmin ? '_admin' : ''}`}
			onPageChangeStart={handelePageChangeStart}
			onPageChangeEnd={handelePageChangeEnd}
			onPageChangeError={handelePageChangeError}
		>
			<ThemeProviderWrapper>
				<DialogProvider pages={pages} FullscreenDialogComponent={FullscreenCarouselDialog}>
					<NotifyProvider context={notifyContext}>
						<Main isAdmin={isAdmin}/>
						{!isCzech && !isFactory && (
							<UserTypeDialog open={userDialogOpen === true}/>
						)}
					</NotifyProvider>
				</DialogProvider>
			</ThemeProviderWrapper>
		</PageDataProvider>
	);
}

function Main({isAdmin}) {
	const theme = useTheme();
	const [ {userType} ] = useUser();
	const prevUserType = usePrevious(userType);
	const prevIsAdmin = usePrevious(isAdmin);
	const [ pageData = {}, {setPageData} ] = usePageData();
	const { meta: appMeta = {} } = appData || {};
	const { prevPageKey } = pageData;
	const { colorSchemeSelector } = theme;

	useEffect(() => {
		if (prevIsAdmin && prevIsAdmin !== isAdmin) {
			setPageData();
		}
	}, [isAdmin, prevIsAdmin, setPageData]);

	useEffect(() => {
		if (prevPageKey && prevUserType !== userType) {
			setPageData({userType});
		}
	}, [userType, prevUserType, setPageData, prevPageKey]);

	return (
		<>
			<Helmet>
				<html {...{[colorSchemeSelector]: theme.config.colorMode}}/>
				<title>{pageData.meta?.title || `${pageData.headline || pageData.title} | ${appMeta.title}`}</title>
				<link rel="canonical" href={`${appData.url}${pageData.canonical ?? pageData.route ?? pageData.pathname}`}/>
				<meta name="description" content={pageData.meta?.description || appMeta.description} key="description"/>
				<meta name="author" content={appMeta.author} key="author"/>
				<meta name="copyright" content={appMeta.copyright} key="copyright"/>
				<meta name="theme-color" content={theme.palette.background.paper}/>
				<meta name="format-detection" content="telephone=no"/>
			</Helmet>
			<CssBaseline/>
			<NoScriptHint/>
			<ScrollRestoration/>
			<NProgress {...(!theme.config.darkMode && {color: theme.vars.palette.primary.main})}/>
			<AppLoading>
				{messages.loading}
			</AppLoading>
			<Outlet context={pageData}/>
		</>
	);
}

export default function App() {
	const {
		userContext = {},
		authContext = {}
	} = initialState;

	return (
		<ErrorBoundary>
			<AuthProvider context={authContext}>
				<UserProvider context={userContext}>
					<Providers/>
				</UserProvider>
			</AuthProvider>
		</ErrorBoundary>
	);
}
