import types from './types.cjs';
import {HOST_URL} from 'lib/helpers';

function sessionRequest() {
	return {
		type: types.SESSION_REQUEST
	};
}

function sessionError(errorMessage) {
	window.dispatchEvent(new CustomEvent('userAuthFailed'));
	return {
		type: types.SESSION_ERROR,
		errorMessage
	};
}

export function loginUser(creds) {
	const config = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'X-XSRF-Token': window.__csrf_token__
		},
		credentials: 'include',
		body: `name=${creds.username}&pass=${creds.password}&scope=${creds.scope}`
	};

	return async (dispatch) => {
		dispatch(sessionRequest());

		const response = await fetch(`${HOST_URL}/api/user/session`, config).catch(err => {
			console.error('Error: ', err);
		});

		const data = await response.json().catch(err => {
			console.error('Error: ', err);
		});

		if (!response.ok) {
			logoutUser();
			const errorMessage = (data && data.message) || `${response.status}: ${response.statusText}`;
			return dispatch(sessionError(errorMessage));
		}

		if (data?.profile?.username === creds.username) {
			window.__USER_PROFILE__ = data.profile;
			window.dispatchEvent(new CustomEvent('userLoginSuccess'));

			return dispatch({
				type: types.SESSION_CREATE,
				profile: data.profile
			});
		}

		return dispatch(sessionError());
	};
}

export function logoutUser() {
	const config = {
		method: 'DELETE',
		credentials: 'include',
		headers: {
			'X-XSRF-Token': window.__csrf_token__
		}
	};

	return async (dispatch) => {
		dispatch(sessionRequest());

		const response = await fetch(`${HOST_URL}/api/user/session`, config).catch(err => {
			console.error('Error: ', err);
		});

		if (!response.ok) {
			const errorMessage = `${response.status}: ${response.statusText}`;
			dispatch(sessionError(errorMessage));
		}

		delete window.__USER_PROFILE__;
		window.dispatchEvent(new CustomEvent('userLogoutSuccess'));

		return dispatch({
			type: types.SESSION_DESTROY
		});
	};
}
