import * as React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButtonBase } from '@mui/material';
import { CardActionArea } from '@mui/material';

const ButtonBase = props => (
	<MuiButtonBase
		component="div"
		{...props}
		sx={{
			width: '100%',
			...props.sx
		}}
	/>
);

const ActionArea = React.forwardRef(function ActionArea(props, ref) {
	const {
		to: toProp,
		href,
		children,
		onClick,
		cardActionMode,
		component: Component,
		...rest
	} = props;

	const to = toProp || href;

	const ActionAreaComponent = cardActionMode ? CardActionArea : ButtonBase;

	return (
		to || onClick ? (
			<ActionAreaComponent
				{...rest}
				component={Component}
				ref={ref}
				to={to}
				href={href}
				onClick={onClick}
			>
				{children}
			</ActionAreaComponent>
		) : Component ? (
			<Component {...rest}>
				{children}
			</Component>
		) : (
			children
		)
	);
});

ActionArea.propTypes = {
	children: PropTypes.node.isRequired,
	to: PropTypes.string,
	href: PropTypes.string,
	cardActionMode: PropTypes.bool,
	onClick: PropTypes.func
};

export default React.memo(ActionArea);
