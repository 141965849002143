import React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';
import Drawer from 'react-app/components/Drawer';
import Typography from '@mui/material/Typography';

const useClasses = createClasses((theme) => ({
	root: {
		zIndex: theme.zIndex.drawer + 2
	},
	drawerPaper: {
		width: 320,
		[theme.breakpoints.up('phone')]: {
			width: 340
		},
		[theme.breakpoints.up('sm')]: {
			width: 360
		}
	},
	drawerInner: {
		scrollbarGutter: 'auto',
		paddingRight: 0
	}
}), {
	name: 'NotifyDrawer'
});

function NotifyDrawer(props) {
	const {
		classes: classesProp,
		className,
		children,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Drawer
			drawerWidth={null}
			headerDivider
			headerCloseButton
			headerContent={(
				<Typography px={.5} variant="h6" component="h2">
					Neuigkeiten
				</Typography>
			)}
			anchor="right"
			ModalProps={{
				closeAfterTransition: true
			}}
			classes={{
				...classesProp,
				root: classes.root,
				drawerPaper: classes.drawerPaper,
				drawerInner: classes.drawerInner
			}}
			{...rest}
		>
			{children}
		</Drawer>
	);
}

NotifyDrawer.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node.isRequired
};

export default React.memo(NotifyDrawer);
