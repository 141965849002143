import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

let themeConfig = {};

function getVariantSlots(componentProps = {}, variants = []) {
	if (!variants.length) {
		return [];
	}
	const variantSlots = variants.filter(({ props: variantProps }) => typeof variantProps === 'function'
		? variantProps({ ...componentProps })
		: Object.entries(variantProps).every(([propKey, propValue]) => (
			componentProps[propKey] === propValue
		))
	).map(({ slots = {}, class: root }) => ({root, ...slots}));
	return variantSlots;
}

function mergeVariantSlots(slots, id) {
	return Object.entries(slots).filter(([key]) => key === id).map(([, val]) => val);
}

function getDefaultProps(name) {
	return getThemeConfig()?.components?.[name]?.defaultProps || {};
}

export function getThemeConfig() {
	return themeConfig;
}

export function setThemeConfig(theme) {
	themeConfig = {...theme};
}

export function cn(...styles) {
	return twMerge(clsx(styles));
}

export function useThemeProps({props, name, defaultProps = {}} = {}) {
	const {
		slots,
		classes,
		variants,
		defaultSlots,
		defaultVariants,
		...componentProps
	} = {
		...defaultProps,
		...getDefaultProps(name),
		...props
	};
	return componentProps;
}

export function createClasses(items = {}, {name, mergeClassName = true, merger = cn} = {}) {
	const {variants: baseVariants = [], defaultProps, slots: baseSlots = {}, ...baseClasses} = items;

	return (props) => {
		return useMemo(() => {
			const {
				slots,
				classes,
				className,
				variants = [],
				defaultSlots,
				defaultVariants = [],
				...componentProps
			} = {
				...defaultProps,
				...getDefaultProps(name),
				...props
			};

			const variantSlots = getVariantSlots(componentProps, [...baseVariants, ...defaultVariants, ...variants]);

			return Object.fromEntries(
				Object.keys({...baseSlots, ...baseClasses}).map(key => [key, merger(
					baseClasses[key],
					baseSlots[key],
					defaultSlots?.[key],
					classes?.[key],
					slots?.[key],
					mergeClassName && key === 'root' && className,
					...variantSlots.map(slots => mergeVariantSlots(slots, key)).flat()
				)])
			);
		}, [props]);
	};
}
