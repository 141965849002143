/* global WEBPACK_GOOGLE_ANALYTICS WEBPACK_GOOGLE_ANALYTICS_DEBUG */

// https://github.com/PriceRunner/react-ga4
import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { resetCookieConsent } from 'react-app/components/CookieConsent';
import { BROWSER, LocalStorage, Cookies } from 'lib/helpers';
import { useLocation } from 'react-router-dom';
import { useMemoCallback } from 'react-app/hooks';
import { appData } from 'config/data.cjs';

const { isFactory, isCzech } = appData;

const REAIL_SHOP_DOMAIN = 'www.holzweidauer.shop';
const WHOLESALE_SHOP_DOMAIN = 'shop.holzweidauer.de';

const gtags = {
	'holzweidauer.de': 'G-DQND6T6XE1',
	'holzweidauer.cz': 'G-955RNDXJPJ',
	'holzfabrik-erzgebirge.de': 'G-5X3SFH207E',
	'test.holzweidauer.de': 'G-W6VGMMLXGE'
};

const gtmIds = {
	'holzweidauer.de': 'GTM-P5SJP267',
	'holzweidauer.cz': 'GTM-5Q7DS7S3'
};

function deleteGaCookies() {
	const domain = window.location.hostname === 'localhost' ? 'localhost' : '.holzweidauer.de';

	Object.entries(Cookies.get()).forEach(([name]) => {
		if (name.startsWith('_ga') || name === '_gid') {
			Cookies.remove(name, { path: '/', domain });
		}
	});
}

function shopLinkClickTracker() {
	function clickListener() {
		const elem = this;

		if (!elem) return;

		const href = elem.href;
		let label = '';
		let value = '';

		if (!href) return;

		if (href.includes(REAIL_SHOP_DOMAIN)) {
			label = 'Online-Shop Click';
			value = 1;
		}
		if (href.includes(WHOLESALE_SHOP_DOMAIN)) {
			label = 'Profi-Shop Click';
			value = 2;
		}
		if (label) {
			ReactGA.event({
				category: 'Button',
				action: 'ShopClick',
				label,
				value
			});
		}
	}

	const addShopLinkClickListener = () => {
		const addClickListener = (elem) => {
			if (!elem) return;
			const { href } = elem;
			if (!href) return;

			if (href.includes(REAIL_SHOP_DOMAIN) || href.includes(WHOLESALE_SHOP_DOMAIN)) {
				elem.removeEventListener('click', clickListener);
				elem.addEventListener('click', clickListener);
			}
		};

		document.querySelectorAll('a[href]').forEach(addClickListener);
	};

	addShopLinkClickListener();
	window.addEventListener('pageChangeEnd', () => {
		window.setTimeout(addShopLinkClickListener, 2000);
	});
}

const getUserType = () => Cookies.get('userType') || 'retail';

export function useGa() {
	const location = useLocation();
	const gaRef = useRef(false);

	const initGa = useMemoCallback(() => {
		if (!BROWSER) {
			return;
		}

		const essentialCookiesOnly = LocalStorage.get('essentialCookiesOnly');
		const cookieConsent = Cookies.get('CookieConsent') === 'true';

		ReactGA.gtag('consent', 'default', {
			ad_storage: 'denied',
			ad_user_data: 'denied',
			ad_personalization: 'denied',
			analytics_storage: 'denied',
			wait_for_update: 500
		});

		if (!cookieConsent || essentialCookiesOnly) {
			if (essentialCookiesOnly) {
				deleteGaCookies();
			}
			return;
		}

		if (WEBPACK_GOOGLE_ANALYTICS_DEBUG || WEBPACK_GOOGLE_ANALYTICS) {
			const { domain } = appData;

			const ga = WEBPACK_GOOGLE_ANALYTICS_DEBUG || gtags[domain] ? {
				trackingId: gtags[domain] || 'G-W6VGMMLXGE',
				gaOptions: {},
				gtagOptions: {},
				testMode: Boolean(WEBPACK_GOOGLE_ANALYTICS_DEBUG)
			} : null;

			if (ga) {
				ReactGA.initialize([ga]);
				// https://developers.google.com/tag-platform/security/guides/consent?hl=de&consentmode=basic#gtag.js
				// https://github.com/codler/react-ga4/issues/57

				ReactGA.gtag('consent', 'update', {
					ad_storage: 'granted',
					ad_user_data: 'granted',
					ad_personalization: 'granted',
					analytics_storage: 'granted'
				});

				ReactGA.send({
					hitType: 'pageview',
					page: window.location.pathname + window.location.search,
					...(!isFactory && !isCzech && {
						user_type: getUserType()
					})
				});

				gaRef.current = true;

				if (!isFactory && !isCzech) {
					shopLinkClickTracker();
				}
			}

			if (gtmIds[domain]) {
				TagManager.initialize({
					gtmId: gtmIds[domain]
				});
			}
		}
	});

	const resetGa = useMemoCallback(() => {
		if (BROWSER) {
			ReactGA.gtag('consent', 'update', {
				ad_storage: 'denied',
				ad_user_data: 'denied',
				ad_personalization: 'denied',
				analytics_storage: 'denied'
			});
			ReactGA.reset();
			resetCookieConsent();
			deleteGaCookies();
			gaRef.current = false;
		}
	});

	useEffect(() => {
		if (BROWSER && gaRef.current) {
			ReactGA.send({
				hitType: 'pageview',
				page: location.pathname,
				user_type: getUserType()
			});
		}
	}, [location]);

	return {
		initGa,
		resetGa
	};
}

