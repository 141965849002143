import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import CardActionArea from '../CardActionArea';
import Img from '../Img';
import Link from '../Link';

const useClasses = createClasses((theme) => ({
	root: {
		width: '100%',
		position: 'relative'
	},
	image: {
		display: 'flex',
		flexDirection: 'column'
	},
	cardActionArea: {
		color: theme.vars.palette.common.black
	},
	focusVisible: {},
	focusHighlight: {
		'&:hover': {
			opacity: theme.vars.palette.action.hoverOpacity * 3
		}
	}
}), {
	name: 'RaCardMedia'
});

function CardMedia(props) {
	const {
		classes: classesProp,
		className,
		children,
		to,
		ImgClasses,
		ImgClassName,
		img: impProp,
		CardActionAreaProps,
		...rest
	} = props;

	const classes = useClasses(props);

	const img = impProp ? impProp : (
		<Img
			fullWidth
			{...rest}
			className={clsx(
				classes.image, ImgClassName
			)}
			classes={ImgClasses}
		/>
	);

	const CardActionAreaClasses = (CardActionAreaProps && CardActionAreaProps.classes) || null;

	return (
		<div
			className={classes.root}
		>
			<>
				{!to ? (
					img
				) : (
					<CardActionArea
						component={Link}
						{...CardActionAreaProps}
						to={to}
						className={classes.cardActionArea}
						classes={{
							focusVisible: classes.focusVisible,
							focusHighlight: classes.focusHighlight,
							...CardActionAreaClasses
						}}
					>
						{img}
					</CardActionArea>
				)}
				{children}
			</>
		</div>
	);
}

CardMedia.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	to: PropTypes.string,
	ImgClasses: PropTypes.object,
	CardActionAreaProps: PropTypes.object
};

export default React.memo(CardMedia);
