/* global WEBPACK_DEV */

import * as React from 'react';
import PropTypes from 'prop-types';
import { EventBookingForm } from '.';
import Dialog from 'react-app/components/Dialog';

const componentId = 'EventBooking';

export function useEventBookingDialogProps(props) {
	const [ dialogOpen, setDialogOpen ] = React.useState(false);

	function handleDialogOpen() {
		setDialogOpen(true);
	}

	function handleDialogClose(event, reason) {
		if (!WEBPACK_DEV && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
			return;
		}
		setDialogOpen(false);
	}

	return {
		dialogOpen,
		handleDialogOpen,
		handleDialogClose
	};
}

function EventBookingDialog(props) {
	const {
		classes,
		className,
		id,
		title,
		subtitle,
		open,
		onClose,
		isBookable,
		eventCategory,
		...rest
	} = props;

	return (
		isBookable && eventCategory ? (
			<Dialog
				open={open}
				onClose={onClose}
				fullWidth
				maxWidth="sm"
				fullScreen="phone"
				{...rest}
				title={`Buchungsanfrage für ${title}`}
				subtitle={subtitle}
				scrollConfig={{
					xs: 'paper',
					md: 'body',
					lg: 'paper'
				}}
			>
				<EventBookingForm
					componentId={`${componentId}Form_${id}`}
					extraValues={{
						id,
						title,
						subtitle,
						category: eventCategory
					}}
				/>
			</Dialog>
		) : null
	);
}

EventBookingDialog.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	isBookable: PropTypes.bool.isRequired,
	eventCategory: PropTypes.object.isRequired
};

export default React.memo(EventBookingDialog);
