import * as React from 'react';
import PropTypes from 'prop-types';
import ReactErrorBoundary from 'react-error-boundary';
import ErrorComponent from '../ErrorComponent';
import { Global } from '@emotion/react';

const styles = {
	body: {
		margin: 0,
		padding: 0
	}
};

function ErrorBoundary(props) {
	const {
		children,
		onError,
		disabled = false,
		FallbackComponent = ErrorComponent,
		...rest
	} = props;

	return (
		<>
			<Global styles={styles}/>
			<ReactErrorBoundary
				{...rest}
				FallbackComponent={!disabled ? FallbackComponent : null}
				onError={onError}
			>
				{children}
			</ReactErrorBoundary>
		</>
	);
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
	onError: PropTypes.func,
	disabled: PropTypes.bool,
	FallbackComponent: PropTypes.elementType
};

export const withErrorBoundary = (config) => (Component) => {
	return (props) => (
		<ErrorBoundary {...config}>
			<Component {...props} />
		</ErrorBoundary>
	);
};

export default React.memo(ErrorBoundary);
