module.exports = {
	sale: {
		maxWidth: 600,
		width: {
			xs: 150,
			md: 360,
			lg: 600
		},
		aspectRatio: {
			xs: 1,
			md: 1.5,
			lg: 1.5
		},
		defaultSize: 'lg'
	},
	news: {
		maxWidth: 960,
		width: {
			xs: 480,
			lg: 960
		},
		aspectRatio: 2.222222,
		defaultSize: 'lg'
	},
	assortmentCard: {
		maxWidth: 480,
		width: 480,
		aspectRatio: 1.5
	},
	teamCard: {
		maxWidth: 280,
		width: 280,
		aspectRatio: 1.2
	},
	catalogCard: {
		maxWidth: 360,
		width: 348,
		aspectRatio: 0.7
	},
	configuratorCard: {
		maxWidth: 360,
		width: 480,
		aspectRatio: 1.7777
	},
	exhibitionCard: {
		maxWidth: 600,
		width: 600,
		aspectRatio: 1.4987
	},
	factoryTeamCard: {
		maxWidth: 480,
		width: 400,
		aspectRatio: 1.1
	}
};
