import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from '../../styles';
import { Collapse } from '@mui/material';
import { useMemoCallback } from '../../hooks';

const useClasses = createClasses((theme) => ({
	root: {
		width: '100%'
	}
}), {
	name: 'RaExpandable'
});

function Expandable(props) {
	const {
		classes: classesProp,
		className,
		children: childrenProp,
		defaultExpanded = false,
		expanded: expandedProp,
		expandable = true,
		CollapseProps,
		onChange,
		...rest
	} = props;

	const classes = useClasses(props);

	const [expandedState, setExpandedState] = React.useState(defaultExpanded);
	const [firstChild, secondChild, ...children] = React.Children.toArray(childrenProp);
	const {current: isControlled} = React.useRef(typeof expandedProp === 'boolean');
	const expanded = isControlled ? expandedProp : expandedState;

	const header = secondChild ? firstChild : null;
	const content = header ? secondChild : firstChild;

	const handleChange = useMemoCallback((event) => {
		if (!isControlled) {
			setExpandedState(!expanded);
		}

		if (onChange) {
			onChange(event, !expanded);
		}
	});

	return (
		<>
			{header && content ? React.cloneElement(header, {
				expanded,
				expandable,
				onChange: handleChange
			}) : null}

			{content ? expandable ? (
				<Collapse
					unmountOnExit
					{...rest}
					{...CollapseProps}
					className={classes.root}
					in={expanded}
				>
					{content}
				</Collapse>
			) : content : null}

			{children}
		</>
	);
}

Expandable.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	defaultExpanded: PropTypes.bool,
	expanded: PropTypes.bool,
	expandable: PropTypes.bool,
	CollapseProps: PropTypes.object,
	onChange: PropTypes.func
};

export default React.memo(Expandable);
