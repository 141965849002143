import { createContext } from 'react-app/context';
import * as actions from './actions';
import reducer from './reducer';

const {
	Context,
	ContextProvider,
	useContext
} = createContext(actions, reducer);

export function AuthProvider({children, ...rest}) {
	return (
		<ContextProvider {...rest}>
			{children}
		</ContextProvider>
	);
}

export {
	useContext as useAuth,
	reducer as authReducer,
	actions as authActions
};

export default Context;
