import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { useMemoCallback } from '../../hooks';

const useClasses = createClasses((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		width: '100%',
		maxWidth: '100%',
		margin: theme.spacing(0, 0, -1),
		...theme.mixins.gutters('paddingX')
	},
	dot: {
		background: 'none',
		border: 'none',
		borderRadius: '50%',
		outline: 0,
		padding: theme.spacing(.5),
		margin: theme.spacing(.75),
		listStyleType: 'none',
		cursor: 'pointer',
		WebkitTapHighlightColor: 'transparent',
		'&:focus': {
			boxShadow: 'currentColor 0px 0px 0px 2px'
		},
		'&:after': {
			transition: theme.transitions.create('opacity', {
				easing: theme.transitions.easing.easeInOut,
				duration: theme.transitions.duration.comlex
			}),
			display: 'block',
			borderRadius: '50%',
			width: theme.spacing(1.5),
			height: theme.spacing(1.5),
			content: '""',
			backgroundColor: 'currentColor',
			opacity: .5
		}
	},
	smallDot: {
		margin: theme.spacing(.5),
		'&&:after': {
			width: theme.spacing(1),
			height: theme.spacing(1)
		}
	},
	activeDot: {
		'&&:after': {
			opacity: 1
		}
	}
}), {
	name: 'RaNavDots'
});

const NavDots = React.forwardRef(function NavDots(props, ref) {
	const {
		classes: classesProp,
		className,
		slidesCount = 1,
		currentSlide,
		gotoSlide,
		onClick,
		small = true,
		...rest
	} = props;

	const classes = useClasses(props);

	const dots = [];

	const handleDotClick = useMemoCallback((index) => (event) => {
		gotoSlide && gotoSlide(index);
		if (typeof onClick === 'function') {
			onClick(event);
		}
	});

	for (let index = 0; index < slidesCount; index++) {
		const isActive = index === currentSlide;
		dots.push(
			<li
				key={index}
				data-key={index}
				tabIndex={isActive ? 0 : -1}
				role="tab"
				className={clsx(
					classes.dot,
					small && classes.smallDot,
					isActive && classes.activeDot
				)}
				onClick={handleDotClick(index)}
			/>
		);
	}

	return (
		<ol
			ref={ref}
			role="tablist"
			{...rest}
			className={classes.root}
		>
			{dots}
		</ol>
	);
});

NavDots.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	slidesCount: PropTypes.number,
	currentSlide: PropTypes.number,
	gotoSlide: PropTypes.func,
	small: PropTypes.bool
};

export default React.memo(NavDots);
