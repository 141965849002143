import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from '../../styles';
import { lighten } from '@mui/material/styles';
import ErrorBox from '../ErrorBox';
import ErrorBoundaryFallback from '../ErrorBoundaryFallback';

const useClasses = createClasses((theme) => ({
	root: {
		background: lighten(theme.palette.error.light, .25),
		color: theme.vars.palette.common.white
	},
	dev: {
		background: theme.vars.palette.error.dark
	}
}), {
	name: 'RaErrorComponent'
});

function ErrorComponent(props) {
	const {
		classes: classesProp,
		className,
		children,
		title = 'Es ist ein Fehler aufgetreten!',
		content = 'Bitte entschuldigen Sie die Unanehmlichkeit',
		showButton,
		ErrorBoxComponent = ErrorBox,
		ErrorBoxProps,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<ErrorBoundaryFallback
			{...rest}
			classes={{
				...classesProp,
				root: classes.root,
				dev: classes.dev
			}}
		>
			<ErrorBoxComponent
				title={title}
				content={content}
				showButton={showButton}
				{...ErrorBoxProps}
			>
				{children}
			</ErrorBoxComponent>
		</ErrorBoundaryFallback>
	);
}

ErrorComponent.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	ErrorBoxComponent: PropTypes.elementType,
	ErrorBoxProps: PropTypes.object
};

export default React.memo(ErrorComponent);
