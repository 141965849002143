import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';
import Notification from 'react-app/components/Notification';

const useClasses = createClasses((theme) => {
	const backgroundColor = theme.mixins.alertVariants.info.backgroundColor;

	return {
		root: {
			'&&': {
				top: 'var(--app-bar-height)',
				[theme.breakpoints.up('sm')]: {
					right: 0
				}
			},
			marginTop: 0,
			[theme.breakpoints.up('sm')]: {
				marginTop: theme.spacing(-1.5)
			},
			'& > .MuiPaper-root': {
				backgroundColor,
				fontWeight: 500,
				'&:before': {
					position: 'absolute',
					top: theme.spacing(-2),
					left: 'auto',
					right: theme.spacing(6.25),
					[theme.breakpoints.up('phone')]: {
						right: theme.spacing(5.25)
					},
					[theme.breakpoints.up('sm')]: {
						right: theme.spacing(1.75)
					},
					content: '""',
					width: 0,
					height: 0,
					borderStyle: 'solid',
					borderWidth: theme.spacing(1),
					borderColor: `transparent transparent ${backgroundColor} transparent`
				}
			}
		}
	};
}, {
	name: 'NotifyPrompt'
});

const anchorOrigin = {
	vertical: 'top',
	horizontal: 'right'
};

function NotifyPrompt(props) {
	const {
		classes: classesProp,
		className,
		id,
		message,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Notification
			closeButtonLabel="OK"
			variant="info"
			{...rest}
			anchorOrigin={anchorOrigin}
			message={message}
			className={classes.root}
		/>
	);
}

NotifyPrompt.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
};

export default React.memo(NotifyPrompt);
