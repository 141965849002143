import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter, RouterProvider, createBrowserRouter, matchRoutes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CacheProvider } from '@emotion/react';
import { BrowserProvider } from '../context/browser';
import { AppProvider } from '../context/app';
import { AppDataProvider } from '../context/app-data';
import { createEmotionCache } from '../styles';

export const renderer = async (options = {}) => {
	const {
		App: AppComponent,
		cacheOptions,
		routes
	} = options;

	const lazyMatches = matchRoutes(routes, window.location)?.filter((m) => m.route.lazy);
	if (lazyMatches && lazyMatches?.length > 0) {
		await Promise.all(
			lazyMatches.map(async (m) => {
				let routeModule = await m.route.lazy();
				Object.assign(m.route, {
					...routeModule,
					lazy: undefined
				});
			})
		);
	}

	const cache = createEmotionCache(cacheOptions);
	const router = Array.isArray(routes) && routes.length ? createBrowserRouter(routes) : null;
	const App = router ? null : AppComponent || require('components/App').default;

	const { browserContext, appContext } = window.__ROOT_CONTEXT__ || {};
	const appData = window.__APP_DATA__ || {};

	const hydrate = () => {
		startTransition(() => {
			hydrateRoot(
				document.getElementById('root'),
				<StrictMode>
					<HelmetProvider>
						<BrowserProvider context={browserContext}>
							<AppProvider context={appContext}>
								<AppDataProvider context={appData}>
									<CacheProvider value={cache}>
										{router ? (
											<RouterProvider router={router}/>
										) : (
											<BrowserRouter>
												<App/>
											</BrowserRouter>
										)}
									</CacheProvider>
								</AppDataProvider>
							</AppProvider>
						</BrowserProvider>
					</HelmetProvider>
				</StrictMode>
			);
		});
	};

	if (typeof requestIdleCallback === 'function') {
		requestIdleCallback(hydrate);
	} else {
		setTimeout(hydrate, 1);
	}
};

const clientRenderer = (options = {}) => {
	if (module.hot) {
		module.hot.accept([
			'components/App',
			'react-app/styles'
		], () => renderer);
	}
	return renderer(options);
};

export default clientRenderer;
