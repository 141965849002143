import * as React from 'react';
import Button from 'components/Button';
import EventIcon from '@mui/icons-material/Event';
import EventBookingDialog, { useEventBookingDialogProps } from './EventBookingDialog';
import useEventProps from './useEventProps';

export function useEventBooking(props) {
	const {
		id,
		title,
		subtitle,
		ButtonProps,
		DialogProps
	} = props;

	const {
		label,
		isBookable,
		eventCategory
	 } = useEventProps(props);

	const {
		dialogOpen,
		handleDialogOpen,
		handleDialogClose
	} = useEventBookingDialogProps(props);

	const button = isBookable ? (
		<Button
			color="primary"
			size="small"
			startIcon={<EventIcon/>}
			{...ButtonProps}
			onClick={handleDialogOpen}
		>
			Anmelden
		</Button>
	) : null;

	const dialog = isBookable ? (
		<EventBookingDialog
			{...DialogProps}
			open={dialogOpen}
			onClose={handleDialogClose}
			id={id}
			title={title}
			subtitle={subtitle}
			isBookable={isBookable}
			eventCategory={eventCategory}
		/>
	) : null;

	return {
		label,
		isBookable,
		eventCategory,
		button,
		dialog
	};
}

function EventBooking(props) {
	const {
		isBookable,
		button,
		dialog
	} = useEventBooking(props);

	return isBookable ? (
		<>
			{button}
			{dialog}
		</>
	) : null;
}

export default React.memo(EventBooking);
