import { createContext } from 'react-app/context';
import * as actions from './actions';
import reducer from './reducer';
import { usePageData } from 'react-app/context/page-data';

const {
	Context,
	ContextProvider,
	useContext
} = createContext(actions, reducer);

function useDeferredUser() {
	const [ userState, setUserState ] = useContext();
	const [ pageData ] = usePageData() || [];
	const [, userType ] = pageData?.prevPageKey?.split(/_(?!.*_)/) || [];
	const isRetail = userType === 'retail';
	const isWholesale = userType === 'wholesale';

	return [{
		...userState,
		...((isRetail || isWholesale) && {
			userType,
			isRetail,
			isWholesale
		})
	}, setUserState];
}

export {
	useDeferredUser,
	useContext as useUser,
	ContextProvider as UserProvider,
	reducer as userReducer,
	actions as userActions
};

export default Context;
