import * as React from 'react';
import useWebShare from 'react-app/hooks/useWebShare';
import { useDialog } from 'react-app/components/DialogProvider';
import { useMemoCallback } from 'react-app/hooks';
import { useBrowser } from 'react-app/context/browser';
import { isPdf, isImage, isCatalog } from 'lib/helpers';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ShopIcon from '@mui/icons-material/ShoppingCart';
import ShareIcon from '@mui/icons-material/Share';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import ZoomIcon from '@mui/icons-material/ZoomOutMap';
import Button from 'components/Button';
import Link from 'components/Link';
import TimeAgo from 'javascript-time-ago';
import de from 'javascript-time-ago/locale/de';
import cs from 'javascript-time-ago/locale/cs';
import { useDeferredUser } from 'context/user';
import { appData } from 'config/data.cjs';

const { isCzech, messages } = appData;

TimeAgo.addLocale(isCzech ? cs : de);

const timeAgo = new TimeAgo(isCzech ? 'cs-CZ' : 'de-DE');

export default function useNewsProps(props) {
	const {
		title,
		subtitle,
		contentHtml: html,
		link: linkProp,
		shopLink: retailShopLink,
		profiShopLink,
		filesData,
		imageSrc,
		fullWidth = false,
		dialogMode,
		isVideoSrc,
		videoButton,
		youtubeParams,
		noActionButton,
		noInfoButton,
		noInfoIcon,
		noShareIcon,
		noFullWidthIcon,
		url,
		publicFrom,
		showPublishedDate = true,
		showContent,
		buttons: buttonsProp,
		icons: iconsProp
	} = props;

	const {openDialog} = useDialog();
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [ {isRetail = true} ] = useDeferredUser();

	const shopLink = isRetail ? retailShopLink : profiShopLink;

	const browser = useBrowser();
	const { isWidthUp } = browser;
	const mdUp = isWidthUp('md', browser.breakpoint);
	const { share, isSupported: shareSupported } = useWebShare();

	const link = isVideoSrc ? undefined : linkProp;
	const iframeSrc = youtubeParams?.iframeSrc || linkProp;
	const isPdfSrc = React.useMemo(() => isPdf(link), [link]);
	const isImageSrc = isImage(link);
	const isCatalogSrc = isCatalog(link);
	const zoomImage = imageSrc && (!mdUp || !fullWidth);

	const hasContent = Boolean(html);

	const timeAgoLabel = showPublishedDate && publicFrom ? timeAgo.format(new Date(publicFrom)) : '';
	const timeAgoSubtitle = timeAgoLabel ? `${subtitle ? `${ subtitle } — ` : ''}${timeAgoLabel}` : subtitle;

	const images = React.useMemo(() => (
		Array.isArray(filesData) ? filesData.filter(item => isImage(item.fileName)).map(item => item.fileSrc) : []
	), [filesData]);

	const multiImages = images.length > 1;

	const handleShareClick = useMemoCallback(() => {
		if (shareSupported && 'share' in navigator) {
			share({
				title,
				text: subtitle,
				url
			});
		}
	});

	const pageDialogProps = React.useMemo(() => (
		(isPdfSrc || isVideoSrc || isCatalogSrc || (zoomImage && !link)) ? {
			title,
			onClick: openDialog,
			'data-src': iframeSrc || link || (multiImages ? images : imageSrc),
			'data-title': title
		} : link ? {
			to: link
		} : null
	), [
		iframeSrc,
		imageSrc,
		images,
		isPdfSrc,
		isCatalogSrc,
		isVideoSrc,
		link,
		multiImages,
		openDialog,
		title,
		zoomImage
	]);

	const handleDialogOpen = useMemoCallback(() => {
		setDialogOpen(true);
	});

	const handleDialogClose = useMemoCallback(() => {
		setDialogOpen(false);
	});

	const buttons = React.useMemo(() => [
		...(buttonsProp ? [
			<React.Fragment key={0}>
				{buttonsProp}
			</React.Fragment>
		] : []),

		...(shopLink ? [
			<Button
				key={1}
				to={shopLink}
				color="primary"
				startIcon={<ShopIcon/>}
			>
				{messages.button.order}
			</Button>
		] : !noActionButton && (zoomImage || isVideoSrc || link) && (!html || fullWidth) ? [
			<Button
				key={2}
				to={isVideoSrc ? null : link}
				{...pageDialogProps}
				startIcon={isVideoSrc ? <PlayIcon/> : link ? <InfoIcon/> : <ZoomIcon/>}
			>
				{isVideoSrc ? messages.button.video : link ? messages.button.info : messages.button.largeView}
			</Button>
		] : !noInfoButton && !fullWidth && html && !showContent ? [
			<Button
				key={3}
				{...(url && !dialogMode ? {
					to: url
				} : {
					onClick: handleDialogOpen
				})}
				startIcon={<InfoIcon/>}
			>
				{messages.button.info}
			</Button>
		] : [])
	], [
		url,
		dialogMode,
		zoomImage,
		html,
		link,
		shopLink,
		isVideoSrc,
		fullWidth,
		pageDialogProps,
		noActionButton,
		noInfoButton,
		handleDialogOpen,
		showContent,
		buttonsProp
	]);

	const icons = React.useMemo(() => [
		...(iconsProp ? [
			<React.Fragment key={0}>
				{iconsProp}
			</React.Fragment>
		] : []),

		...(!noInfoIcon && (shopLink || noInfoButton) && !showContent && !fullWidth && html ? [
			<IconButton
				key={1}
				{...(url && !dialogMode ? {
					to: url,
					component: Link
				} : {
					onClick: handleDialogOpen
				})}
				color="inherit"
				title={messages.button.info}
			>
				<InfoIcon fontSize="small"/>
			</IconButton>
		] : []),

		...(!noInfoIcon && shopLink && !html && (link || isVideoSrc) ? [
			<IconButton
				{...pageDialogProps}
				key={2}
				title={isVideoSrc ? messages.button.video : messages.button.info}
				color="inherit"
				to={isVideoSrc ? null : link}
				component={link ? Link : undefined}
			>
				{isVideoSrc ? (
					<PlayIcon fontSize="small"/>
				) : (
					<InfoIcon fontSize="small"/>
				)}
			</IconButton>
		] : []),

		...(!noShareIcon && title && url && shareSupported ? [
			<IconButton
				key={3}
				title={messages.button.share}
				color="inherit"
				onClick={handleShareClick}
			>
				<ShareIcon fontSize="small"/>
			</IconButton>
		] : []),

		...(!noFullWidthIcon && !videoButton && zoomImage && (shopLink || link || isVideoSrc || (html && !fullWidth)) ? [
			<IconButton
				key={4}
				onClick={openDialog}
				data-src={multiImages ? images : imageSrc}
				data-title={title}
				title={messages.button.largeView}
				color="inherit"
			>
				<ZoomIcon fontSize="small"/>
			</IconButton>
		] : []),
	], [
		html,
		link,
		shopLink,
		url,
		isVideoSrc,
		videoButton,
		fullWidth,
		pageDialogProps,
		shareSupported,
		handleShareClick,
		openDialog,
		title,
		zoomImage,
		imageSrc,
		images,
		multiImages,
		noShareIcon,
		noInfoIcon,
		noFullWidthIcon,
		handleDialogOpen,
		showContent,
		noInfoButton,
		iconsProp,
		dialogMode
	]);

	const hasButtons = buttons.length > 0;
	const hasIcons = icons.length > 0;

	return {
		openDialog,
		shareSupported,
		dialogOpen,
		setDialogOpen,
		html,
		link,
		shopLink,
		iframeSrc,
		isPdfSrc,
		isImageSrc,
		isCatalogSrc,
		zoomImage,
		images,
		multiImages,
		hasContent,
		handleShareClick,
		pageDialogProps,
		handleDialogOpen,
		handleDialogClose,
		buttons,
		icons,
		hasButtons,
		hasIcons,
		timeAgoLabel,
		timeAgoSubtitle
	};
}
