import * as React from 'react';
import { categories, eventCategories } from 'config/news.cjs';
import Label from 'react-app/components/Label';

const styles = {
	label: {
		paddingRight: 1.5,
		borderRadius: '0px 0px 12px 0',
		position: 'absolute',
		textAlign: 'center',
		marginLeft: 0,
		top: 0,
		left: 0
	},
	labelSoldOut: {
		'&&': {
			backgroundColor: '#e30613',
			color: '#fff'
		},
		position: 'absolute',
		top: 'auto',
		margin: 1.5,
		right: 0,
		bottom: 0
	},
};

export default function useEventProps(props) {
	const {
		event,
		isEvent,
		category,
		soldOut,
		showLabel
	} = props;

	const eventCategory = React.useMemo(() => (
		eventCategories?.[category] || []
	).find(item => item?.id === event), [category, event]);

	const isBookable = isEvent && !soldOut && (eventCategory?.bookable);

	const labelContent = category > 0 && categories?.[category];
	const labelColor = category === 1 ? 'secondary': category === 2 ? 'primary' : null;

	const label = labelContent || soldOut ? (
		<>
			{showLabel && labelContent && (
				<Label sx={styles.label} color={labelColor || 'info'}>
					{labelContent}
				</Label>
			)}
			{soldOut && (
				<Label sx={styles.labelSoldOut} color="error" size="medium">
					AUSGEBUCHT
				</Label>
			)}
		</>
	) : null;

	return {
		eventCategory,
		isBookable,
		label
	};
}
