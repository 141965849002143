import * as React from 'react';
import PropTypes from 'prop-types';
import useAsyncReducer from '../hooks/useAsyncReducer';

const mapActions = (actions, dispatch, state) => {
	const dispatchActions = {};

	for (const [name, fn] of Object.entries(actions)) {
		dispatchActions[name] = (...params) => dispatch(fn(...params));
	}

	return dispatchActions;
};

export function useActionsReducer(actions = {}, reducer, initialState = {}) {
	const [state, dispatch] = useAsyncReducer(reducer, {
		...reducer.initialState,
		...initialState
	});

	const actionsRef = React.useRef();

	if (!actionsRef.current) {
		actionsRef.current = mapActions(actions, dispatch, state);
	}

	return [{...state}, actionsRef.current, dispatch];
}

export default function createContext(actions = {}, reducer) {
	if (typeof reducer !== 'function') {
		throw new Error('Reducer must be a function!');
	}

	const Context = React.createContext();
	const {Provider, Consumer} = Context;

	const useReducer = (reducer, initialState = {}) => {
		const [state, dispatchActions, dispatch] = useActionsReducer(actions, reducer, {
			...reducer.initialState,
			...initialState
		});

		return [state, dispatchActions, dispatch];
	};

	const ContextProvider = ({children, context = {}}) => (
		<Provider value={useReducer(reducer, context)}>
			{children}
		</Provider>
	);

	ContextProvider.propTypes = {
		children: PropTypes.node.isRequired,
		context: PropTypes.object
	};

	return {
		Context,
		ContextProvider,
		ContextConsumer: Consumer,
		useReducer,
		useContext: () => React.useContext(Context)
	};
}
