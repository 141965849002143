import {
	BROWSER,
	HOST_URL,
	CACHE_SUPPORT,
	isExternalLink,
	delay
} from '../lib/helpers';

export const imagesCached = [];
export const imagesError = [];

export default class Lazyload {
	#props;

	constructor(config = {}) {
		if (!BROWSER || 'loading' in HTMLImageElement.prototype) {
			return;
		}

		config = {
			enableCache: true,
			enableSWCache: true,
			cacheId: HOST_URL,
			hostUrl: HOST_URL,
			rootMargin: '0px',
			threshold: 0.1,
			...config
		};

		const props = this.#props = {
			config,
			observer: null
		};

		props.getImageSrc = (img => {
			return img.getAttribute('data-src');
		});

		props.handleImageLoaded = (img, src) => {
			src = src || props.getImageSrc(img);

			const parent = img.parentElement;

			if (parent) {
				window.requestAnimationFrame(() => {
					parent.classList.remove(
						'img-loading',
						'img-loading-wave',
						'img-loading-pulse',
						'img-loading-spinner'
					);

					if (!src || imagesError.includes(src)) {
						parent.classList.add('img-error');
					} else {
						parent.classList.remove('img-error');
					}
				});
			}

			if (!imagesError.includes(src)) {
				if (!imagesCached.includes(src)) {
					imagesCached.push(src);
				}
				img.removeAttribute('data-src');
			}
		};

		props.handleImageError = (img, src) => {
			if (!imagesError.includes(src)) {
				imagesError.push(src);
			}
			props.handleImageLoaded(img, src);
		};

		props.createObserver = () => {
			if (BROWSER && 'IntersectionObserver' in window) {
				props.observer = new IntersectionObserver((entries) => {
					entries.forEach((entry) => {
						if (entry.intersectionRatio > 0) {
							const img = entry.target;
							this.loadImage(img)
								.then(() => props.observer.unobserve(img))
								.catch(console.error);
						}
					});
				}, {
					rootMargin: config.rootMargin,
					threshold: config.threshold
				});
			}
		};

		this.getImagesFromCache();
	}

	get observer() {
		const { observer, createObserver } = this.#props;
		if (BROWSER && !observer) {
			createObserver();
		}
		return observer;
	}

	loadImage = (img, src) => {
		const {
			config,
			handleImageLoaded,
			handleImageError,
			getImageSrc
		} = this.#props;

		src = src || getImageSrc(img);
		const parent = img.parentElement;

		return new Promise(async (resolve, reject) => {
			if (!img) {
				return reject('Image element missing!');
			}

			if (!src) {
				return;
			}

			if (imagesCached.includes(src)) {
				img.onload = () => {
					handleImageLoaded(img, src);
					resolve(img);
				};
				img.onerror = (err) => {
					handleImageError(img, src);
					reject(err);
				};
				img.src = src;
				return resolve(img);
			}

			const downloadImg = new Image();

			downloadImg.onload = () => {
				if (imagesError.includes(src)) {
					imagesError.splice(src);
				}
				img.src = src;
				handleImageLoaded(img, src);
				if (!config.enableSWCache || 'serviceWorker' in navigator === false || !navigator.serviceWorker.controller) {
					this.addImageToCache(src, config);
				}
				resolve(img);
				downloadImg.onload = null;
			};

			downloadImg.onerror = (err) => {
				handleImageError(img, src);
				reject(err);
				downloadImg.onerror = null;
			};

			const loadingDelay = img.getAttribute('data-delay');
			if (loadingDelay) {
				await delay(+loadingDelay ?? 0);
			}

			downloadImg.src = src;
		});
	};

	getImagesFromCache = async () => {
		if (!CACHE_SUPPORT) return;

		const {config, handleImageLoaded} = this.#props;

		if (config.enableCache) {
			try {
				const cache = await caches.open(config.cacheId);
				const cacheKeys = await cache.keys();
				cacheKeys.forEach((request, index, array) => {
					if (/\.(jpe?g|png|svg|gif|webp)$/gi.test(request.url)) {
						if (request.url.startsWith(config.hostUrl)) {
							const url = `${request.url.replace(config.hostUrl, '')}`;
							if (!imagesCached.includes(url)) {
								imagesCached.push(url);
							}
						}
					}
				});

				document.querySelectorAll('img[data-src]').forEach(img => {
					const src = img.getAttribute('data-src');
					if (imagesCached.includes(src)) {
						this.loadImage(img, src);
					}
				});
			} catch (err) {
				console.error(err);
			}
		}

		return imagesCached;
	};

	addImageToCache = async (src) => {
		if (!CACHE_SUPPORT) return;

		const {config} = this.#props;

		if (config.enableCache && src && !isExternalLink(src)) {
			try {
				const cache = await caches.open(config.cacheId);
				if (!imagesCached.includes(src)) {
					imagesCached.push(src);
				}
				return await cache.add(src);
			} catch (err) {
				console.error(err);
			}
		}
	};
}
