import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import Fab from '../Fab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const useClasses = createClasses((theme) => ({
	root: {
		top: '50%',
		position: 'absolute',
		transform: 'translateY(-50%)',
		zIndex: 1000
	},
	left: {
		left: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			left: theme.spacing(2)
		},
		[theme.breakpoints.up('md')]: {
			left: theme.spacing(3)
		}
	},
	right: {
		right: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			right: theme.spacing(2)
		},
		[theme.breakpoints.up('md')]: {
			right: theme.spacing(3)
		}
	},
	arrow: {
		fontSize: '2rem'
	},
	hiddenOnTouchDevices: {
		[theme.config.mediaQueries.touch]: {
			display: 'none'
		}
	}
}), {
	name: 'RaNavButton',
	mergeClassName: false
});

const NavButton = React.forwardRef(function NavButton(props, ref) {
	const {
		classes: classesProp,
		className,
		direction = 'left',
		size = 'small',
		hideOnTouchDevices = true,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Fab
			size={size}
			variant="round"
			{...rest}
			ref={ref}
			aria-label={direction === 'left' ? 'zurück' : 'weiter'}
			Icon={direction === 'left' ? ChevronLeftIcon : ChevronRightIcon}
			IconProps={{className: classes.arrow}}
			className={clsx(
				classes.root,
				classes[direction],
				hideOnTouchDevices && classes.hiddenOnTouchDevices,
				className
			)}
		/>
	);
});

NavButton.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	direction: PropTypes.oneOf(['left', 'right']),
	size: PropTypes.string,
	hideOnTouchDevices: PropTypes.bool
};

export default React.memo(NavButton);
