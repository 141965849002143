/* global IS_DEV IS_PUBLIC */

const Lib = require('lib-js');
const IS_BROWSER = typeof window !== 'undefined';

class SharedHelpers {
	static get IS_DEV() {
		return IS_BROWSER ? IS_DEV : process.env.NODE_ENV !== 'production';
	}

	static get IS_BROWSER() {
		return IS_BROWSER;
	}

	static get IS_PUBLIC() {
		return IS_BROWSER ? IS_PUBLIC : process.env.IS_PUBLIC === 'true';
	}

	static get NONCE() {
		const nonce = global.__webpack_nonce__ || global.__nonce__;
		return nonce || null;
	}

	static get HOST_URL() {
		return Lib.getHostUrl();
	}

	static errorHandler(err) {
		if (SharedHelpers.IS_DEV) {
			console.error(err);
		} else {
			console.error(err.type ? err.type + ':' : 'Error:', err.message);
		}
	}

	static registerErrorHandler() {
		if (!IS_BROWSER) {
			process.on('unhandledRejection', SharedHelpers.errorHandler);
			process.on('uncaughtException', SharedHelpers.errorHandler);
		}
	}

	static markdownPrepareString(str) {
		if (!Lib.isString(str)) {
			throw new TypeError('Argument must be a String');
		}
		return str.replace(/\t/g, '');
	}

	static formatDate(date = new Date(), opts = {day: '2-digit', month: '2-digit', year: 'numeric'}) {
		return new Intl.DateTimeFormat('de-DE', opts).format(date);
	}

	static formatTime(date = new Date(), opts = {hour: 'numeric', minute: 'numeric'}) {
		return new Intl.DateTimeFormat('de-DE', opts).format(date);
	}
}

module.exports = SharedHelpers;
