import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, useClsUtils } from 'styles';
import CardActionArea from 'components/CardActionArea';
import Dialog from 'react-app/components/Dialog';
import NewsMedia from './NewsMedia';
import NewsContent from './NewsContent';
import useNewsProps from './useNewsProps';
import { news as sizes } from 'config/sizes.cjs';

const useClasses = createClasses((theme) => ({
	root: {
		'& .MuiDialogContent-root': {
			padding: 0
		}
	},
	cardMediaCenter: {
		[theme.breakpoints.down('tablet')]: {
			margin: 'auto 0'
		}
	},
	subheading: {
		color: theme.vars.palette.text.secondary
	}
}), {
	name: 'NewsDialog'
});

function NewsDialog(props) {
	const {
		className,
		children,
		id,
		title,
		subtitle,
		link,
		shopLink,
		contentHtml: html,
		imageSrc,
		filesData,
		open,
		onClose,
		showMedia = true,
		actions: actionsProp,
		buttons: buttonsProp,
		youtubeParams,
		NewsMediaProps,
		NewsContentProps,
		isVideoSrc,
		iframeSrc,
		url,
		videoButton,
		publicFrom,
		event,
		isEvent,
		category,
		soldOut,
		showLabel,
		...rest
	} = props;

	const { cxCls } = useClsUtils();
	const classes = useClasses(props);

	const {
		buttons,
		icons,
		isPdfSrc,
		openDialog,
		timeAgoSubtitle,
		hasButtons,
		hasIcons,
	} = useNewsProps({...props, fullWidth: true});

	const pageDialogProps =  React.useMemo(() => ((isPdfSrc || isVideoSrc) ? {
		title,
		onClick: openDialog,
		'data-src': iframeSrc || link,
		'data-title': title
	} : null), [
		isPdfSrc,
		isVideoSrc,
		title,
		openDialog,
		iframeSrc,
		link
	]);

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			fullScreen="tablet"
			{...rest}
			title={title}
			subtitle={timeAgoSubtitle}
			open={open}
			onClose={onClose}
			buttons={hasButtons && buttons || null}
			icons={hasIcons && icons || null}
			classes={{
				subheading: classes.subheading
			}}
			className={classes.root}
			scrollConfig={{
				xs: 'paper',
				md: 'body',
				lg: 'paper'
			}}
		>
			{children ? children : (
				<>
					{showMedia &&
						<CardActionArea {...((videoButton || link) ? {...pageDialogProps} : null)}>
							<NewsMedia
								{...NewsMediaProps}
								width={sizes.width.lg}
								height={sizes.width.lg / sizes.aspectRatio}
								videoButton={videoButton}
								className={cxCls(
									!html && classes.cardMediaCenter,
									NewsMediaProps?.className
								)}
								carouselMode
								title={title}
								imageSrc={imageSrc}
								filesData={filesData}
								youtubeParams={youtubeParams}
							/>
						</CardActionArea>
					}
					{html ? (
						<NewsContent
							{...NewsContentProps}
							contentHtml={html}
							subtitle={subtitle}
						/>
					) : null}
				</>
			)}
		</Dialog>
	);
}

NewsDialog.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	link: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	contentHtml: PropTypes.string,
	imageSrc: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	showMedia: PropTypes.bool,
	actions: PropTypes.node,
	youtubeParams: PropTypes.object,
	NewsMediaProps: PropTypes.object,
	NewsContentProps: PropTypes.object
};

export default React.memo(NewsDialog);
