module.exports = {
	SESSION_REQUEST: 'SESSION_REQUEST',
	SESSION_CREATE: 'SESSION_CREATE',
	SESSION_REFRESH: 'SESSION_REFRESH',
	SESSION_DESTROY: 'SESSION_DESTROY',
	SESSION_ERROR: 'SESSION_ERROR',
	SOCKET_CHANNEL_CREATE: 'SOCKET_CHANNEL_CREATE',
	SOCKET_CHANNEL_DESTROY: 'SOCKET_CHANNEL_DESTROY',
	GET_USER_DATA: 'GET_USER_DATA',
	GET_PRICELIST_COUNT: 'GET_PRICELIST_COUNT',
	GET_PRICELIST_DATA: 'GET_PRICELIST_DATA',
	GET_PRICELIST_CATEGORIES: 'GET_PRICELIST_CATEGORIES',
	SET_NEWS_DATA: 'SET_NEWS_DATA',
	DEL_NEWS_DATA: 'DEL_NEWS_DATA',
	SET_SALE_DATA: 'SET_SALE_DATA',
	DEL_SALE_DATA: 'DEL_SALE_DATA',
	SET_SALE_GROUPS: 'SET_SALE_GROUPS',
	DEL_SALE_GROUPS: 'DEL_SALE_GROUPS'
};
