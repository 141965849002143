import { useEffect, useState } from 'react';
import { useBrowser } from '../context/browser';

export default function useWebShare() {
	const browser = useBrowser();
	const [isSupported, setIsSupported] = useState(browser.shareSupport || false);

	useEffect(() => {
		if (!isSupported && 'share' in navigator) {
			setIsSupported(true);
		}
	}, [isSupported]);

	async function share({title, text, url} = {}) {
		if (isSupported && 'share' in navigator) {
			return navigator.share({
				title,
				text,
				url
			}).catch(console.error);
		}
		return null;
	}

	return {
		isSupported,
		share
	};
}
