import * as React from 'react';
import LinkButton from 'react-app/components/LinkButton';
import Link from 'components/Link';

export default React.memo(React.forwardRef(function Button(props, ref) {
	return (
		<LinkButton
			LinkComponent={Link}
			{...props}
			ref={ref}
		/>
	);
}));
